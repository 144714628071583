import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Customer } from '../../class/customer';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';
import { MatSort }  from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  message: string;
  data: any;
  displayedColumns: string[] = ['name', 'address', 'url']; 
  dataSource: MatTableDataSource<any>;
  currentValue:string = '';
  key:string;
  values = '';
  backup_customers = [];
  customersRef: AngularFirestoreCollection<Customer>
  customers = [];
  rep_category:string;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private repService: RepService,
  ) { 
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
  }

  ngOnInit() {
    this.getCustomers();         
  }

  //初期表示データ取得
  getCustomers(){
    let i = 0;
    this.store.collection('customers',
    ref => ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid))
    .snapshotChanges()
    .subscribe(value=>{
      value.forEach((doc)=>{
      if(value[i] == undefined){
        this.message = "(can't get data...)";
        this.data = null;
        return;
      }
      this.key = value[i].payload.doc.id;
      this.data = value[i].payload.doc.data();
      this.data["id"] = this.key;
      this.customers = this.customers.concat(this.data).slice() || [];
      this.message = 'Customer list.'
      i=i+1;
      })
      this.dataSource = new MatTableDataSource(this.customers);
      this.dataSource.sort = this.sort;
      this.backup_customers = this.customers;
    },
    error=>{
      this.message = "(can't get data...)";
      this.data = null;
    });
  }

  searchCustomer(keyword: string){
    let resultArr: Customer[] = [];
    
    for (let customer of this.customers){
      let dataStr = JSON.stringify(customer);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(customer);
      }
    }
    this.customers = resultArr;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }

  onKey(event: any) {
    this.customers = this.backup_customers;
    this.values = event.target.value;
    this.searchCustomer(this.values);
  }

  clearDataSource() {
      this.dataSource = null;
  }

  ngOnDestroy() {
    // this.stateService.state.listMode=/false;
    //this.customerScrollService.clearDoc();
    //this.subscription.forEach(sub => sub.unsubscribe());
  }

  newCustomer(){
    this.router.navigate(['/customer-edit']);
  }
  
  onClickCustomer(select_cs:Customer){
    this.router.navigate(['/customer-detail/' + select_cs.id]);
  }
}
