import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { PaymentService } from '../../service/payment.service';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { RepService } from '../../service/rep.service';
import { MatSpinner } from '@angular/material/progress-spinner';
declare var StripeCheckout:any;

@Component({
  selector: 'app-subscription-option',
  templateUrl: './subscription-option.component.html',
  styleUrls: ['./subscription-option.component.css']
})
export class SubscriptionOptionComponent implements OnInit {
  handler: any;
  showSpinner: boolean = false;
  spin: TemplateRef<any>;
  
  constructor(
    public pmt: PaymentService,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    public repSerice: RepService,
    ) { }

  ngOnInit() {
    this.handler = StripeCheckout.configure({
      key: environment.stripeKey,
      image: '/assets/yourbiz_logo.png',
      locale: 'auto',
      token: token => {
        this.pmt.processPaymentOption(token)
      },
      email:this.afAuth.auth.currentUser.email
    });  
  }

  handlePayment() {
    this.showSpinner = true;
    this.handler.open({
      name: 'YourBiz-Platform',
      description: 'オプションサービス',
      amount: 500,
      currency: 'JPY',
    });  
  } 
}
