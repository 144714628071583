import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Customer } from '../../class/customer';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { RepService } from '../../service/rep.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
  providers:[DatePipe]
})
export class CustomerDetailComponent implements OnInit {
  cs_id:string;
  customer :any;
  message:string = 'wait...';
  data:any;
  key:string;
  rep_category:string;
  customerForm:FormGroup;

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private router:Router,
    private location: Location,
    private repService: RepService,
    public datePipe: DatePipe,
  ) { 
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
  }

  ngOnInit() {
    this.cs_id = this.route.snapshot.paramMap.get('id');
    //console.log("cs_id: ",this.cs_id);
    this.getCustomer();
  }
  get update_date(){return this.customerForm.get("update_date");}
  get rep_name(){return this.customerForm.get("rep_name");}
  get name(){return this.customerForm.get("name");}
  get address(){return this.customerForm.get("address");}
  get tel(){return this.customerForm.get("tel");}
  get url(){return this.customerForm.get("url");}
  get capital(){return this.customerForm.get("capital");}
  get number_employees(){return this.customerForm.get("number_employees");}


  backToList(){
    this.location.back();
  }

  getCustomer(){
    this.store.collection('customers').doc(this.cs_id)
      .valueChanges()
      .subscribe(value=>{
        this.data = value;
        this.customer = this.data;

        let updateDate = this.customer.update_date['seconds']*1000;
        this.customerForm = new FormGroup({
          "update_date": new FormControl(this.datePipe.transform(updateDate, "yyyy/MM/dd"), [Validators.required]), 
          "rep_name": new FormControl(this.customer.rep_name, [Validators.required]),
          "name": new FormControl(this.customer.name, [Validators.required, Validators.maxLength(40)]),
          "address": new FormControl(this.customer.address, [Validators.maxLength(80)]),
          "tel": new FormControl(this.customer.tel, [Validators.maxLength(20)]),
          "url": new FormControl(this.customer.url, [Validators.maxLength(40)]),
          "capital": new FormControl(this.customer.capital, [Validators.min(1), Validators.max(9999999999)]),
          "number_employees": new FormControl(this.customer.number_employees, [Validators.min(1), Validators.max(999999)]),
        });    
      },
      error=>{
        console.log("(ERROR:can't get data...)");
        this.data = null;
      });    
  }

  updateCustomer(){
    let now = new Date();
    this.customerForm.removeControl('update_date');
    this.customerForm.addControl('update_date', new FormControl(now));
    this.customerForm.addControl( 'rep_id', new FormControl(this.afAuth.auth.currentUser.uid));
    this.store.collection('customers').doc(this.cs_id)
    .set(this.customerForm.value);
    this.router.navigate(['/customer-list']);
  }
}
