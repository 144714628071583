import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Project } from '../../class/project';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MatSelect } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Forecast } from '../../class/forecast';
import { ReleaseMonthpickerComponent } from "../release-monthpicker/release-monthpicker.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as _moment from 'moment';
import {Moment} from 'moment';
import { strictEqual } from 'assert';
import { EventEmitter } from 'events';
import { forEach } from '@angular/router/src/utils/collection';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

const moment = _moment;

@Component({
  selector: 'app-forecast-update',
  templateUrl: './forecast-update.component.html',
  styleUrls: ['./forecast-update.component.css']
})
export class ForecastUpdateComponent implements OnInit {
  pj_id:string;
  forecast:any;
  forecast_id:string;
  forecastUpdateForm:FormGroup;
  sales_categories=[];

  public childReleaseMonth: string;
  store_release_month:string;

  constructor(
    private route: ActivatedRoute,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private repService: RepService,
    public pjService: ProjectService,
  ) { 
    if(this.repService.rep_category != 'forecast'){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.forecast_id = this.route.snapshot.paramMap.get('forecast_id');
    this.sales_categories = this.repService.sales_categories;
    this.pjService.getProjectById(this.pj_id);
    this.getForecast();
  }

  ngOnInit() {}

  get forecast_year(){return this.forecastUpdateForm.get("forecast_year");}
  get recorded_year(){return this.forecastUpdateForm.get("recorded_year");}
  get release_month(){return this.forecastUpdateForm.get("release_month");}
  get sales_category(){return this.forecastUpdateForm.get("sales_category");}
  get details(){return this.forecastUpdateForm.get("details");}
  get sales(){return this.forecastUpdateForm.get("sales");}

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  getForecast(){
    //フォーキャストサブコレクションのドキュメントを取得  
    this.store.collection('projects').doc(this.pj_id)
      .collection('forecast').doc(this.forecast_id)
      .valueChanges()
      .subscribe(value=>{
        this.forecast = value;
        //console.log("forecast: ",this.forecast);
        this.store_release_month = this.forecast.release_month;

        this.forecastUpdateForm = new FormGroup({
          "forecast_year": new FormControl(this.repService.forecast_year, [Validators.required]),
          "recorded_year": new FormControl(this.forecast.forecast_year, [Validators.required]),
          "release_month": new FormControl(this.forecast.release_month, [Validators.required]),
          "sales_category": new FormControl(this.forecast.sales_category, [Validators.required]),
          "details": new FormControl(this.forecast.details, [Validators.required]),
          "sales": new FormControl(this.forecast.sales, [Validators.compose([Validators.required, Validators.min(0), Validators.max(1000000000)])]),
        });
        this.childReleaseMonth = this.forecast.release_month;
        return;
    },
    error=>{
      console.log("(can't get data...)");
      this.forecast = null;
    });
  }

  public submitPjForecast(){
    this.updateForecast(this.forecastUpdateForm.value); 
    this.router.navigate(['project-forecast/'+this.pj_id]);   
  }
  
  //forecastサブコレクションを更新 
  updateForecast(update_forecast:FormGroup){       
    const forecast = {
      pj_id: this.pj_id,
      forecast_year: this.repService.forecast_year,
      rep_id: this.afAuth.auth.currentUser.uid, 
      release_month: moment(this.childReleaseMonth).format(),
      sales_category: this.sales_category.value,
      details: this.details.value,
      sales: this.sales.value
    };
    this.store.collection('projects').doc(this.pj_id)
              .collection('forecast').doc(this.forecast_id)
              .update(forecast);     
  }


  //リリース月を子コンポーネント（release-monthpicker.component)から取得
  onReceiveEventFromChild_Release(eventData: string) {
    this.childReleaseMonth = eventData;
    let timestampStr = JSON.stringify(eventData);
    this.forecastUpdateForm.removeControl('release_month');
    this.forecastUpdateForm.addControl( 'release_month', new FormControl(moment(eventData).format()));
  }

  deletePjForecast(){
    let result = confirm('このフォーキャストを削除します。');
    if(result){
      this.store.collection('projects').doc(this.pj_id)
        .collection('forecast').doc(this.forecast_id)
        .delete();
      this.router.navigate(['project-forecast/'+this.pj_id]); 
    }     
  }

}
