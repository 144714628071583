import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Rep } from '../class/rep';

@Injectable({
  providedIn: 'root'
})
export class RepService {
  data:any;
  key:string;
  message:string;
  _sales_categories = [];
  _default_sales_categories = [];
  _rep:any;
  _default_rep:any;
  sales_rep:any;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
  ) { 
    this._sales_categories=[];
  }

  getRep(){ 
    //repsコレクションから該当するユーザーの基本情報（売上区分）を取得
    if(this.afAuth.auth.currentUser.uid){
      this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
      .valueChanges()
      .subscribe(value=>{
        this._rep = value;
        //console.log("rep: ",this._rep);

        let now = new Date();
        //console.log("expire_date.getTime(): ",this._rep.expire_date);
        //console.log("now.getTime(): ",now.getTime());
      
        if(this._rep){
          if((this._rep.rep_category == 'trial') 
              && (this._rep.expire_date < now.getTime())){
            this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
            .set({
              rep_category: 'expire'
            },{merge: true});
          }
        }
      },
      error=>{
        console.log("Error: ", "Can't get rep !");
        this._rep = null;
      });
    }
  }

  get rep(){
    if(this._rep){
      return this._rep;
    }
  }

  get company(){
    if(this._rep){
      if(this._rep.company){
        return this._rep.company;
      }
    }
  }

  get sales_categories(){
    if(this._rep){
      if(this._sales_categories){
        this._sales_categories.length = 0;
        this._sales_categories.push(
          this.rep.sales_category_1,
          this.rep.sales_category_2,
          this.rep.sales_category_3
        );
        //console.log("sales_categories: ",this._sales_categories);
        return this._sales_categories;
      }
    }
  }

  get forecast_year(){
    if(this._rep){
      if(this._rep.forecast_year){
        return this._rep.forecast_year;
      }
    }
  }

  get start_month(){
    if(this._rep){
      if(this._rep.start_month){
        return this._rep.start_month;
      }
    }
  }

  get rep_category(){
    if(this._rep){
      if(this._rep.rep_category){
        return this._rep.rep_category;
      }
    }
  }

  get expire_date(){
    if(this._rep){
      if(this._rep.expire_date){
        return this._rep.expire_date;
      }
    }
  }

  get employee_name(){
    if(this._rep){
      if(this._rep.employee_name){
        return this._rep.employee_name;
      }
    }
  }

  getDefaultRep(){
    //repsコレクションからデフォルトユーザーの基本情報（売上区分）を取得
    if(this.afAuth.auth.currentUser.uid){
      this.store.collection('reps').doc('default_rep')
      .valueChanges()
      .subscribe(value=>{
        this._default_rep = value;
        //console.log("default_rep: ",this._default_rep);
      },
      error=>{
        console.log("Error: ", "Can't get default_rep !");
        this._default_rep = null;
      });
    } 
  }

  get default_rep(){
    if(this._default_rep){
      return this._default_rep;
    }
  }

  get default_company(){
    if(this._default_rep){
      if(this._default_rep.company){
        return this._default_rep.company;
      }
    }
  }

  get default_sales_categories(){
    if(this._default_rep){
      if(this._default_sales_categories){
        this._default_sales_categories.length = 0;
        this._default_sales_categories.push(
          this._default_rep.sales_category_1,
          this._default_rep.sales_category_2,
          this._default_rep.sales_category_3
        );
        //console.log("_defaultsales_categories: ",this._default_sales_categories);
        return this._default_sales_categories;
      }
    }
  }

  get default_forecast_year(){
    if(this._default_rep){
      if(this._default_rep.forecast_year){
        return this._default_rep.forecast_year;
      }
    }
  }

  get default_start_month(){
    if(this._default_rep){
      if(this._default_rep.start_month){
        return this._default_rep.start_month;
      }
    }
  }

  get default_rep_category(){
    if(this._default_rep){
      if(this._default_rep.rep_category){
        return this._default_rep.rep_category;
      }
    }
  }


  get default_employee_name(){
    if(this._default_rep){
      if(this._default_rep.employee_name){
        return this._default_rep.employee_name;
      }
    }
  }

}
