import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Report } from '../../class/report';
import { ReportService } from '../../service/report.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { RepService } from '../../service/rep.service';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportDetailComponent implements OnInit {
  repo_id:string;
  report: any;
  message:string = 'wait...';
  rep_category:string;
  //displayedColumns: string[] = ['item', 'content'];
  

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private router:Router,
    private location: Location, 
    private rsv: ReportService,
    private repService: RepService,
  ) { 
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }

    this.repo_id = this.route.snapshot.paramMap.get('id');
    //console.log("repo_id: ",this.repo_id);
  }

  ngOnInit() {
    this.getReport();
  }
  backToList(){
    this.location.back();
  }

  getReport(){
    //console.log("repo_id2: ",this.repo_id);
    this.store.collection('reports').doc(this.repo_id)
      .valueChanges()
      .subscribe(value=>{
        this.report = value;
        this.message = "(Report Detail !)";
        //console.log("report: ",this.report);
      },
      error=>{
        this.message = "(ERROR:can't get data...)";
        this.report = null;
      });    
  }
}
