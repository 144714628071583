import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Project } from '../../class/project';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MatSelect } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Forecast } from '../../class/forecast';
import { ReleaseMonthpickerComponent } from "../release-monthpicker/release-monthpicker.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import * as _moment from 'moment';
import {Moment} from 'moment';
import { strictEqual } from 'assert';
import { EventEmitter } from 'events';
import { forEach } from '@angular/router/src/utils/collection';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

const moment = _moment;

@Component({
  selector: 'app-project-forecast',
  templateUrl: './project-forecast.component.html',
  styleUrls: ['./project-forecast.component.css']
})
export class ProjectForecastComponent implements OnInit {
  displayedColumns: string[] = ['release_month', 'details', 'sales_category_1', 'sales_category_2', 'sales_category_3'];
  dataSource:[];
  data:any;
  key:string;
  pj_forecast = [];
  currentValue:string = '';
  values = '';
  backup_pj_forecast = [];
  pj_id:string;
  pjForecastForm:FormGroup;
  sales_categories=[];

  public childReleaseMonth: string;
  
  constructor(
    private route: ActivatedRoute,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private repService: RepService,
    public pjService: ProjectService,
  ) { 
    if(this.repService.rep_category != 'forecast'){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.pjService.getProjectById(this.pj_id);
    this.sales_categories = this.repService.sales_categories;   
    this.getForecast();    
  }

  ngOnInit() {
    if(this.repService.sales_categories.length == 0){
      alert("フォーキャスト設定が完了していません。個別案件フォーキャストの登録はフォーキャスト設定を行った後可能にまります。");
      this.router.navigate(['your-page']);
    }else if(!this.repService.forecast_year){
      alert("年度設定が完了していません。個別案件フォーキャストの登録は年度設定を行った後可能にまります。");
      this.router.navigate(['your-page']);
    }else{
      this.pjForecastForm = new FormGroup({
        "forecast_year": new FormControl(this.repService.forecast_year, [Validators.required]),
        "sales_category": new FormControl("", [Validators.required]),
        "details": new FormControl("", [Validators.required, Validators.maxLength(40)]),
        "sales": new FormControl("", [Validators.compose([Validators.required, Validators.min(0), Validators.max(9999999999)])]),
      });
    }
  }
  get forecast_year(){return this.pjForecastForm.get("forecast_year");}
  get sales_category(){return this.pjForecastForm.get("sales_category");}
  get details(){return this.pjForecastForm.get("details");}
  get sales(){return this.pjForecastForm.get("sales");}

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  //フォーキャストサブコレクションのドキュメントを取得  
  getForecast(){
  let i = 0;
  this.store.collection('projects').doc(this.pj_id)
            .collection('forecast',
            ref => ref.orderBy('release_month','asc'))
    .snapshotChanges()
    .subscribe(value=>{
      value.forEach((doc)=>{
        if(value[i] == undefined){
          console.log("(can't get data...)");
          this.data = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        this.data = value[i].payload.doc.data();
        this.data["id"] = this.key;
        this.data["pj_id"] = this.pj_id;

        if(this.data["sales_category"]==this.sales_categories[0]){
          this.data["sales_category_1"] = this.data["sales"];
          this.data["sales_category_2"] = 0;
          this.data["sales_category_3"] = 0;
        }else if(this.data["sales_category"]==this.sales_categories[1]){
          this.data["sales_category_1"] = 0;
          this.data["sales_category_2"] = this.data["sales"];
          this.data["sales_category_3"] = 0;
        }else if(this.data["sales_category"]==this.sales_categories[2]){
          this.data["sales_category_1"] = 0;
          this.data["sales_category_2"] = 0;
          this.data["sales_category_3"] = this.data["sales"];
        }

        this.pj_forecast = this.pj_forecast.concat(this.data).slice() || [];
        //let forecastStr = JSON.stringify(this.pj_forecast);
        //console.log("pj_forecast："+forecastStr);
        i=i+1;
      })
      this.backup_pj_forecast = this.pj_forecast;
    },
    error=>{
      console.log("(can't get data...)");
      this.data = null;
    });
  }

  searchForecast(keyword: string){
    let resultArr: Forecast[] = [];

    for (let forecast of this.pj_forecast){
      let dataStr = JSON.stringify(forecast);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(forecast);
      }
    }
    this.pj_forecast =  resultArr;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }

  public submitPjForecast(){
    this.updateProject();
    this.router.navigate(['reload/'+this.pj_id]); 
  }
  
  //forecastサブコレクションに追加 
  updateProject(){             
    const forecast = {
      pj_id: this.pj_id,
      forecast_year: this.repService.forecast_year,
      rep_id: this.afAuth.auth.currentUser.uid, 
      release_month: moment(this.childReleaseMonth).format(),
      sales_category: this.sales_category.value,
      details: this.details.value,
      sales: this.sales.value
    };
    this.store.collection('projects').doc(this.pj_id)
              .collection('forecast').add(forecast); 
  }

  onKey(event: any) { // without type info
    this.pj_forecast = this.backup_pj_forecast;
    this.values = event.target.value;
    this.searchForecast(this.values);
  }

  onClickAction(select_forecast:Forecast){
    this.router.navigate(['/forecast-update/' + select_forecast.pj_id+'/'+select_forecast.id]);
  }

  //リリース月を子コンポーネント（release-monthpicker.component)から取得
  onReceiveEventFromChild_Release(eventData: string) {
    this.childReleaseMonth = eventData;
    let timestampStr = JSON.stringify(eventData);
    this.pjForecastForm.addControl( 'release_month', new FormControl(moment(eventData).format()));
  }

  /** Gets the total sales of all pj_forecast. */
  getTotalSales() {
    return this.pj_forecast.map(t => Number(t.sales)).reduce((acc, value) => acc + value, 0);
  }
  getTotalSales_1() {
    return this.pj_forecast.map(t => Number(t.sales_category_1)).reduce((acc, value) => acc + value, 0);
  }
  getTotalSales_2() {
    return this.pj_forecast.map(t => Number(t.sales_category_2)).reduce((acc, value) => acc + value, 0);
  }
  getTotalSales_3() {
    return this.pj_forecast.map(t => Number(t.sales_category_3)).reduce((acc, value) => acc + value, 0);
  }

}
