import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { RepService } from '../../service/rep.service';

@Component({
  selector: 'app-year-setting',
  templateUrl: './year-setting.component.html',
  styleUrls: ['./year-setting.component.css']
})
export class YearSettingComponent implements OnInit {;
  data:any;
  message:string;
  key:string;
  rep_id:string;
  budgets = [];
  budget:any;
  forecast_years=[];

  yearSettingForm:FormGroup;
  rep_category: string;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private repService: RepService,
  ) {
    this.rep_category = this.repService.rep_category;
    if(this.rep_category != 'forecast'){
      this.router.navigate(['/your-page']);
    }  
    this.getForecastYear();
  }

  ngOnInit() {

  }

  get forecast_year(){return this.yearSettingForm.get("forecast_year");}
  
  getForecastYear(){
    
    let i = 0;
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
              .collection('budget',
              ref => ref.orderBy('year','desc'))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            this.message = "(can't get data...)";
            this.data = null;
            return;
          }
          this.key = value[i].payload.doc.id;
          this.data = value[i].payload.doc.data();
          this.data["id"] = this.key;      
          this.budgets = this.budgets.concat(this.data).slice() || [];
          //console.log("budgets：",this.budgets);
          this.message = "Budgets !";
          this.forecast_years.push(this.data.year + '-' +this.data.section);
          //console.log("forecast_year：",this.forecast_years);
          i=i+1;
        })
        this.yearSettingForm = new FormGroup({
          "forecast_year": new FormControl(this.forecast_years[0], [Validators.required]),
        });
      },
      error=>{
        this.message = "(can't get data...)";
        this.data = null;
      });
  }

  submitYearSetting(){
    let forecast_year_settings = this.yearSettingForm.value;
    //let forecastYearSettingsStr = JSON.stringify(forecast_year_settings);
    //console.log("forecast_year_settings：",forecastYearSettingsStr);
    this.setForecastYearSettings(forecast_year_settings);
    this.router.navigate(['home']);
  }

  //フォーキャスト年度の設定
  setForecastYearSettings(forecast_year_settings:any){
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
    .set(forecast_year_settings,{merge:true});
  }

}
