import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Report } from '../../class/report';
import { ReportService } from 'src/app/service/report.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  message: string;
  data: any;
  displayedColumns: string[] = ['repo_date', 'cs_name', 'pj_name'];  
  dataSource:[];
  key:string;
  reports = [];
  currentValue:string = '';
  values = '';
  backup_reports = [];
  rep_category:string;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private repService: RepService,
    //private repoService: ReportService,
  ) { 
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
  }

  ngOnInit() {
    this.message = 'wait...';
    this.data = null;
    this.getReports();
  }

  getReports(){
    let i = 0;
    this.store.collection('reports',
      ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid)
      .where('reported_flg', '==', false))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
        if(value[i] == undefined){
          this.message = "(can't get data...)";
          this.data = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        this.data = value[i].payload.doc.data();
        this.data["id"] = this.key;
        this.reports = this.reports.concat(this.data).slice() || [];
        this.message = 'Report list.'
        i=i+1;
        })
        this.backup_reports = this.reports;
      },
      error=>{
        this.message = "(can't get data...)";
        this.data = null;
      });
  }

  get currentUser(){
    //console.log("currentUser: ", this.afAuth.auth.currentUser.uid);
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  searchReport(keyword: string){
    let resultArr: Report[] = [];

    for (let report of this.reports){
      let dataStr = JSON.stringify(report);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(report);
      }
    }
    this.reports =  resultArr;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }
  
  onKey(event: any) { // without type info
    this.reports = this.backup_reports;
    this.values = event.target.value;
    this.searchReport(this.values);
  }

  newReport(){
    this.router.navigate(['/report-edit']);
  }

  onClickReport(select_repo:Report){
    this.router.navigate(['/report-working/' + select_repo.id]);
  }
}
