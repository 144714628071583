// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeKey: 'pk_live_mnKpdl80neZbrmuCdFnf9tdJ00kBn1PjFe',

  firebaseConfig: {
    apiKey: "AIzaSyDs5K05UCPc7GVu-kb2xN3l3eJpQJg03hU",
    authDomain: "yourbiz-platform.firebaseapp.com",
    databaseURL: "https://yourbiz-platform.firebaseio.com",
    projectId: "yourbiz-platform",
    storageBucket: "yourbiz-platform.appspot.com",
    messagingSenderId: "419169035474",
    appId: "1:419169035474:web:69062e94da92dff6c1790b",
    measurementId: "G-3JQTQYC64C"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
