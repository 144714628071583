import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Project } from '../../class/project';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { PreferenceService } from '../../service/preference.service';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {

  pj_id:string;
  data:any;
  data2:any;
  status=[];
  actions:any;
  latest_rank:number;
  visit_count:number[];
  total_visit_count:number;
  report:any;
  preference:any;
  visit_sec_category_names = [];
  rep_category: string;
  cs_name: string;

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private location: Location, 
    private router:Router,
    private prefService: PreferenceService,
    private repService: RepService,
    public pjService: ProjectService,
  ) {     
    this.rep_category = this.repService.rep_category;    
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.pjService.getProjectById(this.pj_id);
    this.getStatus();
    this.visit_sec_category_names = this.prefService.visit_sec_categories;
  }

  ngOnInit() { }

  backToList(){
    this.location.back();
  }

  getStatus(){
    this.store.collection('projects').doc(this.pj_id)
              .collection('status',
              ref => ref.orderBy('follow_date','desc'))
              .valueChanges()
              .subscribe(value=>{
                this.data2 = value;
                this.status[0] = this.data2[0];
                this.getActionPlan();
              },
              error=>{
                this.data2 = null;
              });
  }

  getActionPlan(){
    this.store.collection('projects').doc(this.pj_id)
              .collection('action_plan',
              ref => ref.orderBy('due_date','desc'))
              .valueChanges()
              .subscribe(value=>{
                this.actions = value;
                this.getReport(this.visit_sec_category_names);
              },
              error=>{
                this.actions = null;
              });
  }

  getReport(sec_category_names:any[]){
    this.visit_count = new Array();
    this.visit_count[0] = 0;
    this.visit_count[1] = 0;
    this.visit_count[2] = 0;
    this.visit_count[3] = 0;
    let i = 0;
    this.store.collection('reports',
      ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid)
              .where('reported_flg', '==', true)
              .where('cs_name', '==', this.pjService.project.cs_name)
              .where('pj_name', '==', this.pjService.project.name))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            console.log("(can't get data...)");
            this.data = null;
            return;
          }
          this.data = value[i].payload.doc.data();
          this.report = this.data;

          if(this.report.visit_sec_category == sec_category_names[0]){
            this.visit_count[0] = this.visit_count[0] + 1;
          }else if(this.report.visit_sec_category == sec_category_names[1]){
            this.visit_count[1] = this.visit_count[1] + 1;
          }else if(this.report.visit_sec_category == sec_category_names[2]){
            this.visit_count[2] = this.visit_count[2] + 1;
          }else if(this.report.visit_sec_category == sec_category_names[3]){
            this.visit_count[3] = this.visit_count[3] + 1;
          }
          i=i+1;
        })
        this.total_visit_count = this.visit_count.reduce((acc, value) => acc + value);
      },
      error=>{
        console.log("(can't get data...)");
        this.data = null;
      });
  }

}
