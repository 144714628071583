import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';

@Component({
  selector: 'app-forecast-setting',
  templateUrl: './forecast-setting.component.html',
  styleUrls: ['./forecast-setting.component.css']
})
export class ForecastSettingComponent implements OnInit {

  isLinear:boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  reps: any;
  default_rep: any;
  user_rep: any;
  message: string;
  data1: any;
  data2: any;
  key:string;
  displayedColumns: string[] = ['year', 'section', 'budget_category_1', 'budget_category_2', 'budget_category_3'];
  dataSource:[];
  budgets = [];
  budget:any;
  rep_category: string;

  constructor(
    private _formBuilder: FormBuilder,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router: Router,
    private repService: RepService,
  ) {
    this.rep_category = this.repService.rep_category;
    if(this.rep_category != 'forecast'){
      this.router.navigate(['/your-page']);
    } 
    //console.log('sales_categories: ' + this.repService.sales_categories);
    this.getForecastSetting();
  }

  ngOnInit() {  
    this.secondFormGroup = this._formBuilder.group({
      year: ['', Validators.compose([Validators.min(2019), Validators.max(2099)])],
      section: ['', Validators.compose([Validators.maxLength(15)])],
      budget_category_1: ['', Validators.compose([Validators.min(1), Validators.max(9999999999)])],
      budget_category_2: ['', Validators.compose([Validators.min(1), Validators.max(9999999999)])],
      budget_category_3: ['', Validators.compose([Validators.min(1), Validators.max(9999999999)])]
    });   
  }
  get company(){return this.firstFormGroup.get("company");}
  get employee_name(){return this.firstFormGroup.get("employee_name");}
  get sales_category_1(){return this.firstFormGroup.get("sales_category_1");}
  get sales_category_2(){return this.firstFormGroup.get("sales_category_2");}
  get sales_category_3(){return this.firstFormGroup.get("sales_category_3");}
  get start_month(){return this.firstFormGroup.get("start_month");}
  get year(){return this.secondFormGroup.get("year");}
  get section(){return this.secondFormGroup.get("section");}
  get budget_category_1(){return this.secondFormGroup.get("budget_category_1");}
  get budget_category_2(){return this.secondFormGroup.get("budget_category_2");}
  get budget_category_3(){return this.secondFormGroup.get("budget_category_3");}
  
  getForecastSetting(){
    //初期表示の設定
    //1)はじめてフォーキャスト設定をする時はデフォルトの設定を表示する
    //  repサービスからログインユーザーのドキュメントを取得し、
    //  sales_categoryが設定されていれば、ユーザーの設定値を表示する。
    //2)sales_categoryが設定されていなければ、デフォルト値をフォーキャスト基本情報として設定する。

    if(this.repService.rep.sales_category_1){
      this.reps=this.repService.rep;
    }else{
      this.reps=this.repService.rep;
      this.reps['sales_category_1'] = this.repService.default_rep.sales_category_1;
      this.reps['sales_category_2'] = this.repService.default_rep.sales_category_2;
      this.reps['sales_category_3'] = this.repService.default_rep.sales_category_3;
      this.reps['start_month'] = this.repService.default_start_month;
    }
    //console.log('reps: ' +JSON.stringify(this.reps));
    if(this.reps){
      this.firstFormGroup = this._formBuilder.group({
        company: [this.reps.company,Validators.maxLength(40)],
        employee_name: [this.reps.employee_name,Validators.maxLength(20)],
        sales_category_1: [this.reps.sales_category_1, Validators.compose([Validators.required, Validators.maxLength(10)])],
        sales_category_2: [this.reps.sales_category_2, Validators.compose([Validators.required, Validators.maxLength(10)])],
        sales_category_3: [this.reps.sales_category_3, Validators.compose([Validators.required, Validators.maxLength(10)])],
        start_month: [this.reps.start_month, Validators.compose([Validators.required,Validators.min(1), Validators.max(12)])]
      }); 
    } 
  
    //登録済みの予算情報の取得してリスト表示
    let j = 0;
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
              .collection('budget',
              ref => ref.orderBy('year','desc'))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[j] == undefined){
            this.message = "(can't get data...)";
            this.data2 = null;
            return;
          }
          this.key = value[j].payload.doc.id;
          this.data2 = value[j].payload.doc.data();
          this.data2["id"] = this.key;      
          this.budgets = this.budgets.concat(this.data2).slice() || [];
          //console.log("budgets：",this.budgets);
          this.message = "Budgets !";

          j=j+1;
        })
      },
      error=>{
        this.message = "(can't get data...)";
        this.data2 = null;
      });
  }

  submitForecastSetting(){
    let base_settings = this.firstFormGroup.value;
    let baseSettingsStr = JSON.stringify(base_settings);
    //console.log("base_settings：",baseSettingsStr);

    let budget_settings = this.secondFormGroup.value;
    let budgetSettingsStr = JSON.stringify(budget_settings);
    //console.log("budget_settings：",budgetSettingsStr);

    this.setRepBaseSettings(base_settings);
       
    //予算サブコレクションの追加
    //年度と部署が入力されている場合のみ予算が追加される。
    //同一年度・同一部署の予算がある場合には登録させない。
    //登録済みの予算の変更は同じ年度と部署名を入力して上書き
    //予算の削除および部署名の変更は不可。
    if((budget_settings.year != '') && (budget_settings.section != '')){
      this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
      .collection('budget',
        ref => ref.where('year', '==', budget_settings.year).where('section', '==', budget_settings.section))
      .snapshotChanges()
      .subscribe(value=>{
        if(value[0] == undefined){
          this.message = "(can't get data...)";
          console.log("message：",this.message);
          this.budget = null;
          console.log("budgets2： 新規に作成します。");
          this.addRepBudgetSettings(budget_settings);
          return;
        }                
        this.key = value[0].payload.doc.id;
        this.budget = value[0].payload.doc.data();
        this.budget["id"] = this.key;
        //console.log("budgets1：",this.budget);

        this.setRepBudgetSettings(budget_settings);
        this.budget = null;
        return;
      },
      error=>{
        this.message = "(can't get data...)";
        this.budget = null;
      });
    }
    this.router.navigate(['your-page']);
  }

  //営業担当者コレクションの追加・更新
  setRepBaseSettings(base_settings:any){
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
    .set(base_settings,{merge:true});
  }
  //売上予算サブコレクションの追加
  addRepBudgetSettings(budget_settings:any){
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
    .collection('budget').add(budget_settings);
  }
  //売上予算サブコレクションの更新
  setRepBudgetSettings(budget_settings:any){
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
    .collection('budget').doc(this.budget.id)
    .update({
      budget_category_1: budget_settings.budget_category_1,
      budget_category_2: budget_settings.budget_category_2,
      budget_category_3: budget_settings.budget_category_3
    });
  }
}
