import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Report } from '../class/report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  data1:any;
  data2:any;
  key:string;
  _reports = [];
  _reportsWorking = [];

  constructor(private store: AngularFirestore, 
              private fns: AngularFireFunctions,
              private afAuth: AngularFireAuth,
  ) {

  }

  //作成済みのレポートの取得
  getReports(){
    let i = 0;
    this.store.collection('reports',
      ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid)
              .where('reported_flg', '==', true))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            console.log("can't get data...");
            this.data1 = null;
            return;
          }
          this.key = value[i].payload.doc.id;
          this.data1 = value[i].payload.doc.data();
          this.data1["id"] = this.key;
          this._reports = this._reports.concat(this.data1).slice() || [];
          i=i+1;
        })
      },
      error=>{
        console.log("can't get data... ");
        this.data1 = null;
      });
  }

  //作成中のレポートの取得
  getReportsWorking(){
    let i = 0;
    this.store.collection('reports',
      ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid)
              .where('reported_flg', '==', false))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            console.log("can't get data...");
            this.data2 = null;
            return;
          }
          this.key = value[i].payload.doc.id;
          this.data2 = value[i].payload.doc.data();
          this.data2["id"] = this.key;
          this._reportsWorking = this._reportsWorking.concat(this.data2).slice() || [];
          i=i+1;
        })
      },
      error=>{
        console.log("can't get data... ");
        this.data2 = null;
      });
  }

  get reports(){
    //console.log('reports: ' +this._reports);
    if(this._reports){
      return this._reports;
    }
  }

  get reportsWorking(){
    //console.log('reportsWorking: ' +this._reportsWorking);
    if(this._reportsWorking){
      return this._reportsWorking;
    }
  }
}
