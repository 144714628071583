import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Project } from '../../class/project';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Status } from 'src/app/class/status';
import { RepService} from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

@Component({
  selector: 'app-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.css']
})
export class StatusHistoryComponent implements OnInit {
  displayedColumns: string[] = ['follow_date', 'phase', 'rank', 'sales', 'order_month'];  
  dataSource:[];
  data:any;
  key:string;
  status= [];
  currentValue:string = '';
  values = '';
  backup_status = [];
  pj_id:string;
  rep_category: string;

  constructor(
    private route: ActivatedRoute,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private repService: RepService,
    public pjService: ProjectService,
  ) { 
    this.rep_category = this.repService.rep_category; 
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.pjService.getProjectById(this.pj_id);
    
    this.getStatus();
  }

  ngOnInit() {
    
  }

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  getStatus(){  
    let i = 0;  
    this.store.collection('projects').doc(this.pj_id)
            .collection('status',
            ref => ref.orderBy('follow_date','desc'))
    .snapshotChanges()
    .subscribe(value=>{
      value.forEach((doc)=>{
        if(value[i] == undefined){
          console.log("(can't get data...)");
          this.data = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        this.data = value[i].payload.doc.data();
        this.data["id"] = this.key;
        this.data["pj_id"] = this.pj_id;   
        this.status = this.status.concat(this.data).slice() || [];
        i=i+1;
      })
      this.backup_status = this.status;
    },
    error=>{
      console.log("(can't get data...)");
      this.status = null;
    });
  }

  searchStatus(keyword: string){
    let resultArr: Project[] = [];

    for (let st of this.status){
      let dataStr = JSON.stringify(st);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(st);
      }
    }
    this.status =  resultArr;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }
  
  onKey(event: any) { // without type info
    this.status = this.backup_status;
    this.values = event.target.value;
    this.searchStatus(this.values);
  }

  onClickStatus(select_status:Status){
    this.router.navigate(['/status-detail/' + select_status.pj_id+'/'+select_status.id]);
  }
}
