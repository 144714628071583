import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Customer } from '../class/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  data: any;
  key: string;
  _customers: Customer[];


  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth
  ) { }

  getCustomers(){
    this.store.collection('customers',
    ref => ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid))
      .valueChanges()
      .subscribe(value=>{
        this.data = value;
        this._customers = this.data;
        //console.log("customers: ", +this.customers);
      },
      error=>{
        console.log("Error: ", "Can't get customers !");
        this._customers = null;
      });
  }

  get customers(){
    if(this._customers){
      return this._customers;
    }
    
  }
}
