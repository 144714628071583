import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pj-reload',
  templateUrl: './pj-reload.component.html',
  styleUrls: ['./pj-reload.component.css']
})
export class PjReloadComponent implements OnInit {

  constructor(
    private router:Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.router.navigate(['project-list/']); 
  }

}
