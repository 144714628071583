import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Rep } from '../../class/rep';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { getTreeMultipleDefaultNodeDefsError } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';
import { PaymentService } from '../../service/payment.service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Action } from '../../class/action';

@Component({
  selector: 'app-your-page',
  templateUrl: './your-page.component.html',
  styleUrls: ['./your-page.component.css']
})
export class YourPageComponent implements OnInit {
  displayedColumns: string[] = ['due_date', 'next_action', 'cs_name','pj_name'];
  dataSource:[];
  rep :Rep;
  message:string = 'wait...';
  data:any;
  data2:any;
  data3:any;
  actions = [];
  key:string;
  pj_id:string;
  agree_date:Date;
  basic_status: any;
  option_status: any;
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private fns: AngularFireFunctions,
    private location: Location,
    private router:Router,
    private repService: RepService,
    private pmt: PaymentService,
    private overlay: Overlay,
  ) { 
    this.rep = this.repService.rep;
    if(this.rep){
      this.agree_date = new Date(this.rep.agree_date['seconds']*1000);
    }
    this.getActionToDo();
  }

  ngOnInit() {}

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
        this.repService.employee_name != null ?
          this.repService.employee_name:
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  backToList(){
    this.location.back();
  }

  startBizRepo(){
    if((this.rep) && (this.rep.rep_category != 'repo')){
      this.router.navigate(['/subscription-basic']);
    }
  }

  startForecast(){
    if((this.rep) && (this.rep.rep_category != 'forecast')){
      this.router.navigate(['/subscription-option']);
    }
  }

  cancel(){
    if((this.rep.rep_category == 'repo') || (this.rep.rep_category == 'forecast')){
      var result = window.confirm('サービスの利用を停止しても宜しいですか？\nOKボタンをクリックすると、即時に基本とオプションの両サービスが利用出来なくなります。\nお支払い済みの利用料は返金されません。');  
      if( result ) {
        this.overlayRef.attach(new ComponentPortal(MatSpinner));  

        //YourBiz-Platformの利用停止
        this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
        .set({rep_category: 'cancel'},{merge: true});
        
        //Cloud Functionを呼び出す
        const call = this.fns.httpsCallable('cancelSubscription');

        //Stripeのsubscriptionの停止（基本サービス分）
        this.store.collection('stripe_customers').doc(this.afAuth.auth.currentUser.uid)
        .collection('member-basic').doc('status')
        .valueChanges()
        .subscribe(value=>{
          this.basic_status = value;
          //console.log('basic_status: ' + this.basic_status);
          if(this.basic_status){
            call({
              subscription:this.basic_status.subscription,
              user:this.afAuth.auth.currentUser.displayName,
              email:this.afAuth.auth.currentUser.email,
              type:'basic'
            })
            .subscribe(resp=>{
              this.store.collection('stripe_customers').doc(this.afAuth.auth.currentUser.uid)
              .collection('member-basic').doc('status').delete()
              .then(()=>{
                console.log('member-basic.status delete!');
              });
              console.log('cancel successfull !');
            },
            err=>{
              console.log({err});
            });
          } 
        });

        //Stripeのsubscriptionの停止（オプションサービス分）
        this.store.collection('stripe_customers').doc(this.afAuth.auth.currentUser.uid)
        .collection('member-option').doc('status')
        .valueChanges()
        .subscribe(value=>{
          this.option_status = value;
          //console.log('option_status: ' + this.option_status);
          if(this.option_status){
            call({
              subscription:this.option_status.subscription,
              user:this.afAuth.auth.currentUser.displayName,
              email:this.afAuth.auth.currentUser.email,
              type:'option'
            })
            .subscribe(resp=>{
              this.store.collection('stripe_customers').doc(this.afAuth.auth.currentUser.uid)
              .collection('member-option').doc('status').delete()
              .then(()=>{
                console.log('member-option.status delete!');
              });
              console.log('cancel successfull !');
            },
            err=>{
              console.log({err});
            });            
          }
        });
        this.overlayRef.detach();            
        this.router.navigate(['/home']);
      }
    }
  }

  toTerms(){
    this.router.navigate(['/terms']);
  }

  getActionToDo(){
    let i = 0;
    this.store.collectionGroup("action_plan",
    ref => ref.where("rep_id", "==", this.afAuth.auth.currentUser.uid)
              .where('action_status', '==', '未実施')
              .orderBy('due_date','asc'))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            console.log("(can't get data...)");
            this.data2 = null;
            return;
          }
          this.key = value[i].payload.doc.id;
          this.data = value[i].payload.doc.data();
          this.data["id"] = this.key;       
          this.actions = this.actions.concat(this.data).slice() || [];
          //console.log("actions:" +JSON.stringify(this.actions));
          //console.log("Action to Do !");
          i=i+1;
        })
      },
      error=>{
        console.log("(can't get data...)");
        this.data = null;
      });          
  }

  onClickAction(select_act:Action){
    this.store.collection('projects',
    ref=>ref.where('name', '==', select_act.pj_name)
            .where('cs_name', '==', select_act.cs_name)
            .where('rep_id', '==', this.afAuth.auth.currentUser.uid))
    .snapshotChanges()
    .subscribe(value=>{
      if(value[0] == undefined){
        console.log("(can't get data...3)");
        this.data3 = null;
        return;
      }
      this.pj_id = value[0].payload.doc.id;
      this.router.navigate(['/action-plan/' + this.pj_id]);
    },
    error=>{
      console.log("(can't update data...3)");
      this.data3 = null;
    });  
  }
}
