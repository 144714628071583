import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { 
  MatButtonModule,
  MatMenuModule,
  MatTableModule,
  MatDatepickerModule, 
  MatDialog,
  MatDialogModule,
  MatRadioModule,
  MatNativeDateModule,
  MatStepperModule, 
  MatAutocompleteModule, 
 } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';

import { AppComponent } from './app.component';
import { ThousandSuffixesPipe } from './class/thousandSuffixes.pipe';
import { ReportListComponent } from './component/report-list/report-list.component';
import { ReportEditComponent } from './component/report-edit/report-edit.component';
import { ReportDetailComponent } from './component/report-detail/report-detail.component';
import { OrderMonthpickerComponent } from './component/order-monthpicker/order-monthpicker.component';
import { ProjectRegisterDialog } from './component/report-edit/report-edit.component';
import { CustomerListComponent } from './component/customer-list/customer-list.component';
import { CustomerEditComponent } from './component/customer-edit/customer-edit.component';
import { CustomerDetailComponent } from './component/customer-detail/customer-detail.component';
import { ReleaseMonthpickerComponent } from './component/release-monthpicker/release-monthpicker.component';
import { ReportComponent } from './component/report/report.component';
import { ReportWorkingComponent } from './component/report-working/report-working.component';
import { ProjectListComponent } from './component/project-list/project-list.component';
import { ProjectDetailComponent } from './component/project-detail/project-detail.component';
import { ProjectForecastComponent } from './component/project-forecast/project-forecast.component';
import { ForecastListComponent } from './component/forecast-list/forecast-list.component';
import { StatusHistoryComponent } from './component/status-history/status-history.component';
import { ActionPlanComponent } from './component/action-plan/action-plan.component';
import { ActionUpdateComponent } from './component/action-update/action-update.component';
import { ActionDetailComponent } from './component/action-detail/action-detail.component';
import { StatusUpdateComponent } from './component/status-update/status-update.component';
import { StatusDetailComponent } from './component/status-detail/status-detail.component';
import { YourPageComponent } from './component/your-page/your-page.component';
import { PreferenceComponent } from './component/preference/preference.component';
import { ForecastSettingComponent } from './component/forecast-setting/forecast-setting.component';
import { ForecastUpdateComponent } from './component/forecast-update/forecast-update.component';
import { YearSettingComponent } from './component/year-setting/year-setting.component';
import { HomeComponent } from './component/home/home.component';
import { IconLoginComponent } from './component/icon-login/icon-login.component';
import { IconLogoutComponent } from './component/icon-logout/icon-logout.component';
import { IconAccountComponent } from './component/icon-account/icon-account.component';
import { IconExternalLinkComponent } from './component/icon-external-link/icon-external-link.component';
import { InfoDetailComponent } from './component/info-detail/info-detail.component';
import { TermsComponent } from './component/terms/terms.component';
import { ReloadComponent } from './component/reload/reload.component';
import { RepoCsvDownloadDialog } from './component/report-list/report-list.component';
import { PjCsvDownloadDialog } from './component/project-list/project-list.component';
import { ForecastCsvDownloadDialog } from './component/forecast-list/forecast-list.component';
import { RepoReloadComponent } from './component/repo-reload/repo-reload.component';
import { PjReloadComponent } from './component/pj-reload/pj-reload.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ActionReloadComponent } from './component/action-reload/action-reload.component';
import { NumericDirective } from './directive/numeric.directive';
import { SubscriptionBasicComponent} from './component/subscription-basic/subscription-basic.component';
import { SubscriptionOptionComponent} from './component/subscription-option/subscription-option.component';
import { PaymentService } from './service/payment.service';

const routes:Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'report', component: ReportComponent },
  { path: 'report-list', component: ReportListComponent },
  { path: 'report-list/:cs_name/:name', component: ReportListComponent },
  { path: 'report-detail/:id', component: ReportDetailComponent },
  { path: 'report-edit', component: ReportEditComponent },
  { path: 'report-working/:id', component: ReportWorkingComponent },
  { path: 'project-list', component: ProjectListComponent },
  { path: 'project-detail/:id', component: ProjectDetailComponent },
  { path: 'project-forecast/:id', component: ProjectForecastComponent },
  { path: 'forecast-list', component: ForecastListComponent },
  { path: 'forecast-update/:id/:forecast_id', component: ForecastUpdateComponent },
  { path: 'action-plan/:id', component: ActionPlanComponent },
  { path: 'action-update/:id/:act_id', component: ActionUpdateComponent },
  { path: 'action-detail/:id/:act_id', component: ActionDetailComponent },  
  { path: 'status-history/:id', component: StatusHistoryComponent },
  { path: 'status-update/:id', component: StatusUpdateComponent },
  { path: 'status-detail/:id/:st_id', component: StatusDetailComponent },
  { path: 'customer-list', component: CustomerListComponent },
  { path: 'customer-detail/:id', component: CustomerDetailComponent },
  { path: 'customer-edit', component: CustomerEditComponent },
  { path: 'your-page', component: YourPageComponent },
  { path: 'preference', component: PreferenceComponent },
  { path: 'forecast-setting', component: ForecastSettingComponent },
  { path: 'year-setting', component: YearSettingComponent },
  { path: 'info-detail/:id', component: InfoDetailComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'reload/:id', component: ReloadComponent },
  { path: 'repo-reload', component: RepoReloadComponent },
  { path: 'pj-reload', component: PjReloadComponent },
  { path: 'action-reload/:id', component: ActionReloadComponent },
  { path: 'subscription-basic', component: SubscriptionBasicComponent },
  { path: 'subscription-option', component: SubscriptionOptionComponent },
];

const fire_config = {
  apiKey: "AIzaSyDs5K05UCPc7GVu-kb2xN3l3eJpQJg03hU",
  authDomain: "yourbiz-platform.com",
  databaseURL: "https://yourbiz-platform.firebaseio.com",
  projectId: "yourbiz-platform",
  storageBucket: "yourbiz-platform.appspot.com",
  messagingSenderId: "419169035474",
  appId: "1:419169035474:web:69062e94da92dff6c1790b",
  measurementId: "G-3JQTQYC64C"
}

@NgModule({
  declarations: [
    AppComponent,
    ThousandSuffixesPipe,
    ReportListComponent,
    ReportEditComponent,
    ReportDetailComponent,
    OrderMonthpickerComponent,
    ProjectRegisterDialog,
    CustomerListComponent,
    CustomerEditComponent,
    CustomerDetailComponent,
    ReleaseMonthpickerComponent,
    ReportComponent,
    ReportWorkingComponent,
    ProjectListComponent,
    ProjectDetailComponent,
    ProjectForecastComponent,
    ForecastListComponent,
    StatusHistoryComponent,
    ActionPlanComponent,
    ActionUpdateComponent,
    ActionDetailComponent,
    StatusUpdateComponent,
    StatusDetailComponent,
    YourPageComponent,
    PreferenceComponent,
    ForecastSettingComponent,
    ForecastUpdateComponent,
    YearSettingComponent,
    HomeComponent,
    IconLoginComponent,
    IconLogoutComponent,
    IconAccountComponent,
    InfoDetailComponent,
    TermsComponent,
    IconExternalLinkComponent,
    ReloadComponent,
    RepoCsvDownloadDialog,
    PjCsvDownloadDialog,
    ForecastCsvDownloadDialog,
    RepoReloadComponent,
    PjReloadComponent,
    ActionReloadComponent,
    NumericDirective,
    SubscriptionBasicComponent,
    SubscriptionOptionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatDividerModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatNativeDateModule,
    MatStepperModule, 
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(fire_config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    ScrollingModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    OverlayModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  entryComponents: [ProjectRegisterDialog, RepoCsvDownloadDialog, PjCsvDownloadDialog, ForecastCsvDownloadDialog, MatSpinner],
  exports: [ RepoCsvDownloadDialog, PjCsvDownloadDialog, ForecastCsvDownloadDialog ],
  providers: [
    { provide: FunctionsRegionToken, useValue: 'us-central1'},PaymentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
