import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  userId: string;
  membership: any;
  basic_status: any;
  option_status: any;

  constructor(
    private store: AngularFirestore, 
    private afAuth: AngularFireAuth
  ) { 
    this.afAuth.authState.subscribe((auth) => {
      if (auth) this.userId = auth.uid;     
    });
  }

  processPaymentBasic(token: any) {  
    this.store.collection('stripe_customers').doc(this.userId)
    .collection('member-basic').doc('token')
    .set(token, {merge:true});
  }

  processPaymentOption(token: any) {  
    this.store.collection('stripe_customers').doc(this.userId)
    .collection('member-option').doc('token')
    .set(token, {merge:true});
  }
}
