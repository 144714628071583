import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

@Component({
  selector: 'app-status-detail',
  templateUrl: './status-detail.component.html',
  styleUrls: ['./status-detail.component.css']
})
export class StatusDetailComponent implements OnInit {

  pj_id:string;
  st_id:string;
  status:any;
  action:any;
  rep_category:string;

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private location: Location, 
    private router:Router,
    private repService: RepService,
    public pjService: ProjectService,
  ) { 
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }   
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.st_id = this.route.snapshot.paramMap.get('st_id');
    this.pjService.getProjectById(this.pj_id);
    this.getStatus();
  }

  ngOnInit() {}

  backToList(){
    this.location.back();
  }

  getStatus(){
    this.store.collection('projects').doc(this.pj_id)
    .collection('status').doc(this.st_id)
    .valueChanges()
    .subscribe(value=>{
      this.status = value;
      //console.log("status: ",this.status);
    },
    error=>{
      this.status = null;
    });
  }

}
