import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { async } from '@angular/core/testing';
import { Status } from '../../class/status';
import { PreferenceService } from '../../service/preference.service';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';
import * as _moment from 'moment';
import {Moment} from 'moment';
import { strictEqual } from 'assert';
import { EventEmitter } from 'events';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

const moment = _moment;

@Component({
  selector: 'app-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.css']
})
export class StatusUpdateComponent implements OnInit {
  public childOderMonth: string;
  public childReleaseMonth: string;

  pj_id:string;
  st_id:string;
  status: any;
  store_order_month:string;
  store_release_month:string;
  statusUpdateForm:FormGroup;
  phases=[];
  ranks=[];
  rep_category: string;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute, 
    private router: Router,
    private location: Location,
    private prefService: PreferenceService,
    private repService: RepService,
    public pjService: ProjectService,
    @Inject(LOCALE_ID) private locale: string
  ) { 
    this.rep_category = this.repService.rep_category; 
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.pjService.getProjectById(this.pj_id);
    //console.log("pj_id: ",this.pj_id);
    //console.log("afAuth.auth.currentUser.uid is :"+this.afAuth.auth.currentUser.uid);
    this.phases = this.prefService.phases;
    //console.log("phases: ",this.phases);
    this.ranks = this.prefService.ranks;
    //console.log("ranks: ",this.ranks);
    this.getStatus();

  }

  ngOnInit() {}

  get follow_date(){return this.statusUpdateForm.get("follow_date");}
  get phase(){return this.statusUpdateForm.get("phase");}
  get rank(){return this.statusUpdateForm.get("rank");}
  get order_month(){return this.statusUpdateForm.get("order_month");}
  get release_month(){return this.statusUpdateForm.get("release_month");}
  get sales(){return this.statusUpdateForm.get("sales");}
  get competitor(){return this.statusUpdateForm.get("competitor");}
  get comment(){return this.statusUpdateForm.get("comment");}

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  getStatus(){
    this.store.collection('projects').doc(this.pj_id)
    .collection('status',
    ref => ref.orderBy('follow_date','desc'))
      .valueChanges()
      .subscribe(value=>{
      this.status = value;
      //console.log("status: ",this.status);

      this.store_order_month = this.status[0].order_month;
      //console.log("store_order_month: ",this.store_order_month);
      this.store_release_month = this.status[0].release_month;
      //console.log("store_release_month: ",this.store_release_month);

      this.statusUpdateForm = new FormGroup({
        "follow_date": new FormControl(formatDate(this.status[0].follow_date.toDate(),"dd/MM/yyyy",this.locale),[Validators.required]),
        "phase": new FormControl(this.status[0].phase,[Validators.required]),
        "rank": new FormControl(this.status[0].rank,[Validators.required]),
        "order_month": new FormControl(this.status[0].order_month,[Validators.required]),
        "release_month": new FormControl(this.status[0].release_month,[Validators.required]),
        "sales": new FormControl(this.status[0].sales,[Validators.required, Validators.min(0), Validators.max(9999999999)]),
        "competitor": new FormControl(this.status[0].competitor,[Validators.required, Validators.maxLength(200)]),
        "comment": new FormControl("",[Validators.required, Validators.maxLength(200)]),
      });      
      this.childOderMonth = this.status[0].order_month;
      this.childReleaseMonth = this.status[0].release_month;
      },
      error=>{
        console.log("(can't get data...)");
        this.status = null;
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  backToList(){
    this.location.back();
  }

  //受注月を子コンポーネント（order-monthpicker.component)から取得
  onReceiveEventFromChild_Order(eventData: string) {
    this.childOderMonth = eventData;
    //console.log("eventData："+eventData);
    let timestampStr = JSON.stringify(eventData);
    //console.log("order_month_timestamp：",timestampStr);
    this.statusUpdateForm.removeControl('order_month');
    this.statusUpdateForm.addControl( 'order_month', new FormControl(moment(eventData).format()));
  }

  //リリース月を子コンポーネント（release-monthpicker.component)から取得
  onReceiveEventFromChild_Release(eventData: string) {
    this.childReleaseMonth = eventData;
    //console.log("eventData："+eventData);
    let timestampStr = JSON.stringify(eventData);
    //console.log("release_month_timestamp：",timestampStr);
    this.statusUpdateForm.removeControl('release_month');
    this.statusUpdateForm.addControl( 'release_month', new FormControl(moment(eventData).format()));
  }

  submitStatus(){
    let now = new Date();
    this.statusUpdateForm.removeControl('follow_date');
    this.statusUpdateForm.addControl('follow_date', new FormControl(now));
    this.updateProject(this.statusUpdateForm.value); 
    this.router.navigate(['project-detail/'+ this.pj_id]);  
  }

  updateProject(update_status:FormGroup){
    //let update_dataStr = JSON.stringify(update_status);
    //console.log("update_status："+update_dataStr);

    //statusサブコレクションに追加 
    this.store.collection('projects').doc(this.pj_id)
              .collection('status')
              .add(update_status);          
  }
  
}
