import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { RepService } from '../../service/rep.service';

@Component({
  selector: 'app-info-detail',
  templateUrl: './info-detail.component.html',
  styleUrls: ['./info-detail.component.css']
})
export class InfoDetailComponent implements OnInit {
  info_id: string;
  info: any;

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private router:Router,
    private location: Location, 
  ) { 
    this.info_id = this.route.snapshot.paramMap.get('id');
    console.log("repo_id: ",this.info_id);
    this.getInfo();
  }

  ngOnInit() {}

  backToList(){
    this.location.back();
  }

  getInfo(){
    this.store.collection('info').doc(this.info_id)
      .valueChanges()
      .subscribe(value=>{
        this.info = value;
        console.log("info: ",this.info);
      },
      error=>{
        console.log("(ERROR:can't get data...)");
        this.info = null;
      });    
  }
}
