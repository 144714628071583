import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Project } from '../../class/project';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';
import { Location, DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { MatSort }  from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
  providers:[DatePipe],
})
export class ProjectListComponent implements OnInit {
  message: string;
  data: any;
  data2:any;
  data3:any;
  displayedColumns: string[] = ['name', 'cs_name', 'rank', 'sales', 'order_month'];
  dataSource: MatTableDataSource<any>;
  key:string;
  projects = [];
  reported_projects =[];
  currentValue:string = '';
  values = '';
  backup_projects = [];
  status:any;
  actions:any;
  rep_category:string;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private location: Location,
    private repService: RepService,
    private pjService: ProjectService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.projects = this.pjService.projects
    this.data = null;
  }
  
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.projects);
    this.dataSource.sort = this.sort;        
    this.backup_projects = this.projects;
/*    
    let i = 0;
    this.store.collection('projects',
      ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
        if(value[i] == undefined){
          this.message = "(can't get data...)";
          this.data = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        this.data = value[i].payload.doc.data();
        this.data["id"] = this.key;
        this.projects = this.projects.concat(this.data).slice() || [];
        this.message = 'Project list.';
        this.getStatus(i);
        this.getActionPlan(i);
        i=i+1;
        })
        console.log("projects: ",this.projects);
        this.dataSource = new MatTableDataSource(this.projects);
        this.dataSource.sort = this.sort;        
        this.backup_projects = this.projects;
      },
      error=>{
        this.message = "(can't get data...)";
        this.data = null;
      });
*/      
  }

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
        this.afAuth.auth.currentUser.displayName : 
        '(not logined.)' :
      '(not logined.)'; 
  }

  searchReport(keyword: string){
    let resultArr: Project[] = [];

    for (let project of this.projects){
      let dataStr = JSON.stringify(project);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(project);
      }
    }
    //this.projects =  resultArr;
    this.dataSource = new MatTableDataSource(resultArr);
    this.dataSource.sort = this.sort;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }
  
  onKey(event: any) { // without type info
    this.projects = this.backup_projects;
    this.values = event.target.value;
    this.searchReport(this.values);
  }
/*
  getStatus(i:number){
    this.store.collection('projects').doc(this.key)
              .collection('status',
              ref => ref.orderBy('follow_date','desc'))
              .valueChanges()
              .subscribe(value=>{
                this.data2 = value;
                this.status = this.data2;
                if(this.status[0]){
                  this.projects[i]["competitor"] = this.status[0].competitor;
                  this.projects[i]["follow_date"] = this.status[0].follow_date;
                  this.projects[i]["order_month"] = this.status[0].order_month;
                  this.projects[i]["phase"] = this.status[0].phase;
                  this.projects[i]["rank"] = this.status[0].rank;
                  this.projects[i]["release_month"] = this.status[0].release_month;
                  this.projects[i]["sales"] = this.status[0].sales;
                  this.projects[i]["status"] = true;
                }
              },
              error=>{
                this.status = null;
              });
  }

  getActionPlan(i:number){
    this.store.collection('projects').doc(this.key)
              .collection('action_plan',
              ref => ref.orderBy('due_date','desc'))
              .valueChanges()
              .subscribe(value=>{
                this.data3 = value;
                this.actions = this.data3;
                if(this.actions[0]){
                  this.projects[i]["action_date"] = this.actions[0].action_date;
                  this.projects[i]["action_status"] = this.actions[0].action_status;
                  this.projects[i]["due_date"] = this.actions[0].due_date;
                  this.projects[i]["next_action"] = this.actions[0].next_action;
                  this.projects[i]["action_plan"] = true;
                }
              },
              error=>{
                this.status = null;
              });
  }
*/
  download(data:any){
    if(!data){
      alert("出力するプロジェクトがありません。");
      return;
    }else{
      //statsuとaction_planが登録されていないprojectは除外する
      const REPORTED_PROJECTS = this.projects.filter((v) => (v.status === true)&&(v.action_plan === true));
      this.reported_projects = REPORTED_PROJECTS;
      //console.log("reported_projects: ",this.reported_projects);

      const replacer = (key, value) => value === null ? '' : value;
      const header = Object.keys(this.reported_projects[0]);
      let i = 0;
      this.reported_projects.forEach((doc)=>{
          delete this.reported_projects[i].id;
          delete this.reported_projects[i].rep_id;
          delete this.reported_projects[i].status;
          delete this.reported_projects[i].action_plan;

          if(this.reported_projects[i]['action_date']){
            this.reported_projects[i]['action_date'] = this.datePipe.transform(this.reported_projects[i]['action_date'].toDate(), "yyyy/MM/dd");
          }else{
            this.reported_projects[i]['action_date'] = "-";
          }
          this.reported_projects[i]['due_date'] = this.datePipe.transform(this.reported_projects[i]['due_date'].toDate(), "yyyy/MM/dd");
          this.reported_projects[i]['follow_date'] = this.datePipe.transform(this.reported_projects[i]['follow_date'].toDate(), "yyyy/MM/dd");
          this.reported_projects[i]['order_month'] = this.datePipe.transform(this.reported_projects[i]['order_month'], "yyyy/MM");
          this.reported_projects[i]['release_month'] = this.datePipe.transform(this.reported_projects[i]['release_month'], "yyyy/MM");
          i=i+1;
      })
  
      //Leave off ID col and format
      const actualheader = ["顧客名", "案件名", "営業担当者", "競合", "更新日", 
      "受注月", "フェーズ",  "受注確度", "納入月", "商談金額", "実施日", 
      "実施状況", "実施予定日", "次回アクション" ];
      //console.log(header);
  
      //console.log(this.reported_projects);
      let csv = this.reported_projects.map(row => header.map(fieldName => JSON.stringify
        (row[fieldName], replacer)).join(';'));
               csv.unshift(actualheader.join(';'));
               let csvArray = csv.join('\r\n');
        
               var blob = new Blob([csvArray], { type: 'text/csv' });
               FileSaver.saveAs(blob, "projectsFile.csv");           
    }
  }

  onClickProject(select_pj:any){
    this.router.navigate(['/project-detail/' + select_pj.id]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(PjCsvDownloadDialog);

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      if(result){
        this.download(this.projects);
        this.router.navigate(['pj-reload/']); 
      }
    });
  }
}

@Component({
  selector: 'pj-csv-download-dialog',
  templateUrl: 'pj-csv-download-dialog.html'
})
export class PjCsvDownloadDialog {

}
