import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Project } from '../class/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  data1:any;
  data2:any;
  data3:any;
  key:string;
  _projects = [];
  _project:any;
  private rank:any[];
  store_pj_id:string = '';
  status:any;
  actions:any;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
  ) { 
    this.rank = [];
  }
/*
  getProjects(){
    this.store.collection('projects',
      ref => ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid))
    .valueChanges()
    .subscribe(value=>{
      this.data = value;
      this._projects = this.data;
      console.log("projects: ",this._projects);
    },
    error=>{
      this.data=null;
      console.log("Error: ", "Can't get projects !");
    })
  }
*/

  getProjects(){
    let i = 0;
    this.store.collection('projects',
      ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
        if(value[i] == undefined){
          console.log("can't get data...");
          this.data1 = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        this.data1 = value[i].payload.doc.data();
        this.data1["id"] = this.key;
        this._projects = this._projects.concat(this.data1).slice() || [];
        this.getStatus(i);
        this.getActionPlan(i);
        i=i+1;
        })
        //console.log("_projects: ",this._projects);
      },
      error=>{
        console.log("can't get data...");
        this.data1 = null;
      });
  }

  getStatus(i:number){
    this.store.collection('projects').doc(this.key)
              .collection('status',
              ref => ref.orderBy('follow_date','desc'))
              .valueChanges()
              .subscribe(value=>{
                this.data2 = value;
                this.status = this.data2;
                if(this.status[0]){
                  this._projects[i]["competitor"] = this.status[0].competitor;
                  this._projects[i]["follow_date"] = this.status[0].follow_date;
                  this._projects[i]["order_month"] = this.status[0].order_month;
                  this._projects[i]["phase"] = this.status[0].phase;
                  this._projects[i]["rank"] = this.status[0].rank;
                  this._projects[i]["release_month"] = this.status[0].release_month;
                  this._projects[i]["sales"] = this.status[0].sales;
                  this._projects[i]["status"] = true;
                }
              },
              error=>{
                this.status = null;
              });
  }

  getActionPlan(i:number){
    this.store.collection('projects').doc(this.key)
              .collection('action_plan',
              ref => ref.orderBy('due_date','desc'))
              .valueChanges()
              .subscribe(value=>{
                this.data3 = value;
                this.actions = this.data3;
                if(this.actions[0]){
                  this._projects[i]["action_date"] = this.actions[0].action_date;
                  this._projects[i]["action_status"] = this.actions[0].action_status;
                  this._projects[i]["due_date"] = this.actions[0].due_date;
                  this._projects[i]["next_action"] = this.actions[0].next_action;
                  this._projects[i]["action_plan"] = true;
                }
              },
              error=>{
                this.status = null;
              });
  }

  pushRank(latest_rank:any){
    this.rank.push(latest_rank);
  }

  popRank(){
    this.rank.pop();
  }

  get latest_rank(){
    if(this.rank){
      return this.rank;
    }
  }

  //プロジェクト一覧はこちら（報告実績のあるPJのみ)
  get projects(){
    if(this._projects){
      const pjList = this._projects.filter((pj)=>{
        return(pj.follow_date);
      });
      return pjList;
    }
  }

  //report_editの案件欄のセレクトボックスはこちら(新規案件で一時保存状態のPJも含める)
  get latest_pjs(){
    if(this._projects){
      return this._projects;
    }
  }

  getProjectById(pj_id:string){
    //console.log("store_pj_id: ",this.store_pj_id);
    if(pj_id != this.store_pj_id){
      this.store_pj_id = pj_id;
      this.store.collection('projects').doc(pj_id)
      .valueChanges()
      .subscribe(value=>{
        this._project = value;
        //console.log("project: ",this._project);
      },
      error=>{
        console.log("Error: ", "Can't get project !");
        this._project = null;
      });
    }
  }
  
  get project(){
    if(this._project){
      return this._project;
    }
  }
}
