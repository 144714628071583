import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {
  data:any;
  key:string;
  message:string;
  _visit_sec_categories:string[];
  _phases:string[];
  _ranks:string[];
  _actions:string[];
  preferences:any;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
  ) {
    this._visit_sec_categories=[];
    this._phases=[];
    this._ranks=[];
    this._actions=[];
  }

  getPreferencies(){
    this.store.collection('preferences',
      ref => ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid))
      .valueChanges()
      .subscribe(value=>{
        this.preferences = value;
        //console.log("preferences: ",this.preferences);
      },
      error=>{
        console.log("Error: ", "Can't get preferences !");
        this.preferences = null;
      });
  }

  get ranks(){
    if(this.preferences[0]){
      this._ranks.length = 0;
      this._ranks.push(
        this.preferences[0].base_settings.rank_1,
        this.preferences[0].base_settings.rank_2
      );
      if(this.preferences[0].base_settings.rank_3 != ''){
        this._ranks.push(this.preferences[0].base_settings.rank_3);
      }
      if(this.preferences[0].base_settings.rank_4 != ''){
        this._ranks.push(this.preferences[0].base_settings.rank_4);
      }
      if(this.preferences[0].base_settings.rank_5 != ''){
        this._ranks.push(this.preferences[0].base_settings.rank_5);
      }
      
      //console.log("ranks: ",this._ranks);
      return this._ranks;
    }
  }

  get visit_sec_categories(){
    if(this.preferences[0]){
      this._visit_sec_categories.length = 0;
      this._visit_sec_categories.push(
        this.preferences[0].base_settings.visit_sec_category_1,
        this.preferences[0].base_settings.visit_sec_category_2,
        this.preferences[0].base_settings.visit_sec_category_3
      );
      if(this.preferences[0].base_settings.visit_sec_category_4 != ''){
        this._visit_sec_categories.push(this.preferences[0].base_settings.visit_sec_category_4);
      }
      //console.log("visit_sec_categories: ",this._visit_sec_categories);
      return this._visit_sec_categories;
    }
  }

  get phases(){
    if(this.preferences[0]){
      this._phases.length = 0;
      this._phases.push(
        this.preferences[0].base_settings.phase_1,
        this.preferences[0].base_settings.phase_2,
        this.preferences[0].base_settings.phase_3,
        this.preferences[0].base_settings.phase_4,
        this.preferences[0].base_settings.phase_5
      );
      if(this.preferences[0].base_settings.phase_6 != ''){
        this._phases.push(this.preferences[0].base_settings.phase_6);
      }
      if(this.preferences[0].base_settings.phase_7 != ''){
        this._phases.push(this.preferences[0].base_settings.phase_7);
      }
      if(this.preferences[0].base_settings.phase_8 != ''){
        this._phases.push(this.preferences[0].base_settings.phase_8);
      }
      if(this.preferences[0].base_settings.phase_9 != ''){
        this._phases.push(this.preferences[0].base_settings.phase_9);
      }
      if(this.preferences[0].base_settings.phase_10 != ''){
        this._phases.push(this.preferences[0].base_settings.phase_10);
      }      
      //console.log("phases: ",this._phases);
      return this._phases; 
    }
  }

  get actions(){
    if(this.preferences[0]){
      this._actions.length = 0;
      this._actions.push(
        this.preferences[0].action_settings.action_1,
        this.preferences[0].action_settings.action_2,
        this.preferences[0].action_settings.action_3,
        this.preferences[0].action_settings.action_4,
        this.preferences[0].action_settings.action_5,
        this.preferences[0].action_settings.action_6,
        this.preferences[0].action_settings.action_7,
        this.preferences[0].action_settings.action_8,
        this.preferences[0].action_settings.action_9,
        this.preferences[0].action_settings.action_10
      );
      if(this.preferences[0].action_settings.action_11 != ''){
        this._actions.push(this.preferences[0].action_settings.action_11);
      }
      if(this.preferences[0].action_settings.action_12 != ''){
        this._actions.push(this.preferences[0].action_settings.action_12);
      }
      if(this.preferences[0].action_settings.action_13 != ''){
        this._actions.push(this.preferences[0].action_settings.action_13);
      }
      if(this.preferences[0].action_settings.action_14 != ''){
        this._actions.push(this.preferences[0].action_settings.action_14);
      }
      if(this.preferences[0].action_settings.action_15 != ''){
        this._actions.push(this.preferences[0].action_settings.action_15);
      }
      if(this.preferences[0].action_settings.action_16 != ''){
        this._actions.push(this.preferences[0].action_settings.action_16);
      }
      if(this.preferences[0].action_settings.action_17 != ''){
        this._actions.push(this.preferences[0].action_settings.action_17);
      }
      if(this.preferences[0].action_settings.action_18 != ''){
        this._actions.push(this.preferences[0].action_settings.action_18);
      }
      if(this.preferences[0].action_settings.action_19 != ''){
        this._actions.push(this.preferences[0].action_settings.action_19);
      }
      if(this.preferences[0].action_settings.action_20 != ''){
        this._actions.push(this.preferences[0].action_settings.action_20);
      }   
      //console.log("actions: ",this._actions);
      return this._actions;
    }
  }
}
