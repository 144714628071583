import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css']
})
export class PreferenceComponent implements OnInit {

  isLinear:boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  preferences: any;
  defualt_preferences: any;
  user_preferences: any;
  message: string;
  data: any;
  key:string;

  constructor(
    private _formBuilder: FormBuilder,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router: Router,
  ) {}

  ngOnInit() {  
    //初期表示の設定
    //1)はじめてpreference設定をする時はデフォルトの設定を表示する
    //  preferenceコレクションを走査してログインユーザーのrep_idを持つドキュメント
    //  が無ければデフォルトの値をそのユーザーのpreferenceとして設定する。
    //2)ログインユーザーのrep_idを持つドキュメントが存在する時はユーザーの設定値を表示する
    //  ダミーのuser_preferenceを保持して、走査中にデフォルト値で上書きされないようにする。
    let i = 0;
    this.store.collection('preferences')  
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            this.message = "(can't get data...)";
            this.data = null;
            return;
          }
          this.key = value[i].payload.doc.id;
          this.data = value[i].payload.doc.data();
          this.data["id"] = this.key;
          //console.log("data：",this.data);
          //console.log("rep_id：",this.afAuth.auth.currentUser.uid);
          if(this.data["rep_id"] == this.afAuth.auth.currentUser.uid){
            this.user_preferences = this.data;
            this.preferences = this.data;
          }
          //preferenceコレクションには必ず'default_setting'が存在するので
          //ログインユーザーのrep_idがない場合はデフォルト値を設定する
          if(this.data["id"] == 'default_settings'){
            if(!this.user_preferences){
              this.preferences= this.data;
            }      
          }
          //console.log("preferences：",this.preferences);
          if(this.preferences){
            this.firstFormGroup = this._formBuilder.group({
              visit_sec_category_1: [this.preferences.base_settings.visit_sec_category_1, [Validators.required, Validators.maxLength(10)]],       
              visit_sec_category_2: [this.preferences.base_settings.visit_sec_category_2, [Validators.required, Validators.maxLength(10)]],
              visit_sec_category_3: [this.preferences.base_settings.visit_sec_category_3, [Validators.required, Validators.maxLength(10)]],
              visit_sec_category_4: [this.preferences.base_settings.visit_sec_category_4, Validators.maxLength(10)],     
              rank_1: [this.preferences.base_settings.rank_1, [Validators.required, Validators.maxLength(10)]],
              rank_2: [this.preferences.base_settings.rank_2, [Validators.required, Validators.maxLength(10)]],
              rank_3: [this.preferences.base_settings.rank_3, Validators.maxLength(10)],
              rank_4: [this.preferences.base_settings.rank_4, Validators.maxLength(10)],
              rank_5: [this.preferences.base_settings.rank_5, Validators.maxLength(10)],       
              phase_1: [this.preferences.base_settings.phase_1, [Validators.required, Validators.maxLength(10)]],
              phase_2: [this.preferences.base_settings.phase_2, [Validators.required, Validators.maxLength(10)]],
              phase_3: [this.preferences.base_settings.phase_3, [Validators.required, Validators.maxLength(10)]],
              phase_4: [this.preferences.base_settings.phase_4, [Validators.required, Validators.maxLength(10)]],
              phase_5: [this.preferences.base_settings.phase_5, [Validators.required, Validators.maxLength(10)]],
              phase_6: [this.preferences.base_settings.phase_6, Validators.maxLength(10)],
              phase_7: [this.preferences.base_settings.phase_7, Validators.maxLength(10)],
              phase_8: [this.preferences.base_settings.phase_8, Validators.maxLength(10)],
              phase_9: [this.preferences.base_settings.phase_9, Validators.maxLength(10)],
              phase_10: [this.preferences.base_settings.phase_10, Validators.maxLength(10)],
            });
            this.secondFormGroup = this._formBuilder.group({
              action_1: [this.preferences.action_settings.action_1, [Validators.required, Validators.maxLength(20)]],
              action_2: [this.preferences.action_settings.action_2, [Validators.required, Validators.maxLength(20)]],
              action_3: [this.preferences.action_settings.action_3, [Validators.required, Validators.maxLength(20)]],
              action_4: [this.preferences.action_settings.action_4, [Validators.required, Validators.maxLength(20)]],
              action_5: [this.preferences.action_settings.action_5, [Validators.required, Validators.maxLength(20)]],
              action_6: [this.preferences.action_settings.action_6, [Validators.required, Validators.maxLength(20)]],
              action_7: [this.preferences.action_settings.action_7, [Validators.required, Validators.maxLength(20)]],
              action_8: [this.preferences.action_settings.action_8, [Validators.required, Validators.maxLength(20)]],
              action_9: [this.preferences.action_settings.action_9, [Validators.required, Validators.maxLength(20)]],
              action_10: [this.preferences.action_settings.action_10, [Validators.required, Validators.maxLength(20)]],
              action_11: [this.preferences.action_settings.action_11, Validators.maxLength(20)],
              action_12: [this.preferences.action_settings.action_12, Validators.maxLength(20)],
              action_13: [this.preferences.action_settings.action_13, Validators.maxLength(20)],
              action_14: [this.preferences.action_settings.action_14, Validators.maxLength(20)],
              action_15: [this.preferences.action_settings.action_15, Validators.maxLength(20)],
              action_16: [this.preferences.action_settings.action_16, Validators.maxLength(20)],
              action_17: [this.preferences.action_settings.action_17, Validators.maxLength(20)],
              action_18: [this.preferences.action_settings.action_18, Validators.maxLength(20)],
              action_19: [this.preferences.action_settings.action_19, Validators.maxLength(20)],
              action_20: [this.preferences.action_settings.action_20, Validators.maxLength(20)],
            });    
          }        
          i=i+1;
        })
      },
      error=>{
        this.preferences = null;
      })
  }
  
  get visit_sec_category_1(){return this.firstFormGroup.get("visit_sec_category_1");}
  get visit_sec_category_2(){return this.firstFormGroup.get("visit_sec_category_2");}
  get visit_sec_category_3(){return this.firstFormGroup.get("visit_sec_category_3");}
  get visit_sec_category_4(){return this.firstFormGroup.get("visit_sec_category_4");}
  get rank_1(){return this.firstFormGroup.get("rank_1");}
  get rank_2(){return this.firstFormGroup.get("rank_2");}
  get rank_3(){return this.firstFormGroup.get("rank_3");}
  get rank_4(){return this.firstFormGroup.get("rank_4");}
  get rank_5(){return this.firstFormGroup.get("rank_5");}
  get phase_1(){return this.firstFormGroup.get("phase_1");}
  get phase_2(){return this.firstFormGroup.get("phase_2");}
  get phase_3(){return this.firstFormGroup.get("phase_3");}
  get phase_4(){return this.firstFormGroup.get("phase_4");}
  get phase_5(){return this.firstFormGroup.get("phase_5");}
  get phase_6(){return this.firstFormGroup.get("phase_6");}
  get phase_7(){return this.firstFormGroup.get("phase_7");}
  get phase_8(){return this.firstFormGroup.get("phase_8");}
  get phase_9(){return this.firstFormGroup.get("phase_9");}
  get phase_10(){return this.firstFormGroup.get("phase_10");}
  get action_1(){return this.secondFormGroup.get("action_1");}
  get action_2(){return this.secondFormGroup.get("action_2");}
  get action_3(){return this.secondFormGroup.get("action_3");}
  get action_4(){return this.secondFormGroup.get("action_4");}
  get action_5(){return this.secondFormGroup.get("action_5");}
  get action_6(){return this.secondFormGroup.get("action_6");}
  get action_7(){return this.secondFormGroup.get("action_7");}
  get action_8(){return this.secondFormGroup.get("action_8");}
  get action_9(){return this.secondFormGroup.get("action_9");}
  get action_10(){return this.secondFormGroup.get("action_10");}
  get action_11(){return this.secondFormGroup.get("action_11");}
  get action_12(){return this.secondFormGroup.get("action_12");}
  get action_13(){return this.secondFormGroup.get("action_13");}
  get action_14(){return this.secondFormGroup.get("action_14");}
  get action_15(){return this.secondFormGroup.get("action_15");}
  get action_16(){return this.secondFormGroup.get("action_16");}
  get action_17(){return this.secondFormGroup.get("action_17");}
  get action_18(){return this.secondFormGroup.get("action_18");}
  get action_19(){return this.secondFormGroup.get("action_19");}
  get action_20(){return this.secondFormGroup.get("action_20");}

  submitPreference(){
    if((!this.visit_sec_category_4)||(this.visit_sec_category_4 == null)){
      this.firstFormGroup.removeControl('visit_sec_category_4');
    }
    if(!this.rank_3){
      this.firstFormGroup.removeControl('rank_3');
    }
    if(!this.rank_4){
      this.firstFormGroup.removeControl('rank_4');
    }
    if(!this.rank_5){
      this.firstFormGroup.removeControl('rank_5');
    }
    if(!this.phase_6){
      this.firstFormGroup.removeControl('phase_6');
    }
    if(!this.phase_7){
      this.firstFormGroup.removeControl('phase_7');
    }
    if(!this.phase_8){
      this.firstFormGroup.removeControl('phase_8');
    }
    if(!this.phase_9){
      this.firstFormGroup.removeControl('phase_9');
    }
    if(!this.phase_10){
      this.firstFormGroup.removeControl('phase_10');
    }
    let base_settings = this.firstFormGroup.value;
    let baseSettingsStr = JSON.stringify(base_settings);
    //console.log("base_settings：",baseSettingsStr);


    if(!this.action_11){
      this.secondFormGroup.removeControl('action_11');
    }
    if(!this.action_12){
      this.secondFormGroup.removeControl('action_12');
    } 
    if(!this.action_13){
      this.secondFormGroup.removeControl('action_13');
    } 
    if(!this.action_14){
      this.secondFormGroup.removeControl('action_14');
    } 
    if(!this.action_15){
      this.secondFormGroup.removeControl('action_15');
    } 
    if(!this.action_16){
      this.secondFormGroup.removeControl('action_16');
    } 
    if(!this.action_17){
      this.secondFormGroup.removeControl('action_17');
    } 
    if(!this.action_18){
      this.secondFormGroup.removeControl('action_18');
    } 
    if(!this.action_19){
      this.secondFormGroup.removeControl('action_19');
    } 
    if(!this.action_20){
      this.secondFormGroup.removeControl('action_20');
    }     
    let action_settings = this.secondFormGroup.value;
    let actionSettingsStr = JSON.stringify(action_settings);
    //console.log("action_settings：",actionSettingsStr);

    //console.log("user_preferences：",this.user_preferences);
    if(!this.user_preferences){
      this.store.collection('preferences')
      .add({
        rep_id: this.afAuth.auth.currentUser.uid,
        base_settings,
        action_settings
      });
    }else{
      this.store.collection('preferences').doc(this.user_preferences['id'])
      .set({
        base_settings,
        action_settings
      },
      {merge:true}
      );
    }

    this.router.navigate(['your-page']);

  }

}
