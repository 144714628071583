import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Info } from '../../class/info';
import { MatSort }  from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  displayedColumns: string[] = ['release_date', 'title', 'category'];  
  dataSource: MatTableDataSource<any>;
  data:any;
  key:string;
  info = [];
  currentValue:string = '';
  values = '';
  backup_info= [];
  info_id:string;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private location: Location,
    private router:Router,
  ) { 
    this.getInfo();
  }

  ngOnInit() {
  }

  getInfo(){  
    let i = 0;  
    this.store.collection('info',
      ref => ref.orderBy('release_date','desc'))
    .snapshotChanges()
    .subscribe(value=>{
      value.forEach((doc)=>{
        if(value[i] == undefined){
          console.log("(can't get data...)");
          this.data = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        this.data = value[i].payload.doc.data();
        this.data["id"] = this.key;   
        this.info = this.info.concat(this.data).slice() || [];
        let dataStr = JSON.stringify(this.info);
        //console.log("info: " +dataStr);
        i=i+1;
      })
      this.dataSource = new MatTableDataSource(this.info);
      this.dataSource.sort = this.sort;
      this.backup_info = this.info;
    },
    error=>{
      console.log("(can't get data...)");
      this.info = null;
    });
  }

  searchInfo(keyword: string){
    let resultArr: Info[] = [];

    for (let inf of this.info){
      let dataStr = JSON.stringify(inf);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(inf);
      }
    }
    this.info =  resultArr;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }
  
  onKey(event: any) { // without type info
    this.info = this.backup_info;
    this.values = event.target.value;
    this.searchInfo(this.values);
  }

  onClickInfo(select_info:Info){
    this.router.navigate(['/info-detail/' + select_info.id]);  
  }
  
  toTerms(){
    this.router.navigate(['/terms']);
  }
}
