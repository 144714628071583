import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Rep } from '../../class/rep';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';
import {MatTableModule} from '@angular/material/table';
import { mdiOpenInNew } from '@mdi/js';

export interface DefinitionElement {
  term: string;
  definition: string;
}

const ELEMENT_DATA: DefinitionElement[] = [
  {term: '（1）本サービス', definition: '当社が運営するサービス及び関連するサービス'},
  {term: '（2）本サイト', definition: '本サービスのコンテンツが掲載されたウェブサイト'},
  {term: '（3）本コンテンツ', definition: '本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称（投稿情報を含む）'},
  {term: '（4）利用者', definition: '本サービスを利用する全ての方'},
  {term: '（5）ＩＤ', definition: '本サービスの利用のために登録利用者が固有に持つ文字列。'},
  {term: '（6）個人情報', definition: '住所、氏名、職業、電話番号等個人を特定することのできる情報の総称'},
  {term: '（7）Googleアカウント', definition: 'Google社が提供するさまざまなサービスを利用するためのアカウント。本サービスはGoogle社のクラウドサービス上で運営しており、Googleアカウントを利用者の認証および利用者が登録するデータの識別に利用します。'},
  {term: '（8）知的財産', definition: '発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報'},
  {term: '（9）知的財産権', definition: '特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利'},
];

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  rep :Rep;
  message:string = 'wait...';
  data:any;
  key:string;
  termsCheckForm:FormGroup;

  displayedColumns: string[] = ['term', 'definition'];
  dataSource = ELEMENT_DATA;
  mdiOpenInNew: string = mdiOpenInNew;

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private location: Location,
    private router:Router,
    private repService: RepService,
  ) { 
    this.rep = this.repService.rep;
  }

  ngOnInit() {
    this.termsCheckForm = new FormGroup({
      "terms_check": new FormControl("", [Validators.required]),
    })
  }
  get terms_check(){return this.termsCheckForm.get("terms_check");}

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  backToList(){
    this.location.back();
  }

  startTrial(){
    if(this.currentUser == '(not logined.)'){
      alert("Googleサービスにログインしてください。右上のログインボタンをクリックするとGoogleアカウントのログイン画面が表示されます。");
    }else{
      if(!this.rep){
        let now = new Date();
        let agree = new Date();
        this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
        .set({
          name: this.afAuth.auth.currentUser.displayName,
          email: this.afAuth.auth.currentUser.email,
          rep_category: 'trial',
          agree_date: agree,
          expire_date: now.setDate(now.getDate() + 30)
        },{merge: true});
        this.router.navigate(['home']);
      }
    }
  }
}
