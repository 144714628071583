import { Component, OnInit, NgZone } from '@angular/core';
import * as firebase from "firebase";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, NavigationEnd } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { PreferenceService } from './service/preference.service';
import { CustomerService } from './service/customer.service';
import { ProjectService } from './service/project.service';
import { RepService } from './service/rep.service';
import { mdiLogin, mdiLogout, mdiAccount } from '@mdi/js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'YourBiz-Platform';
  mdiLogin: string = mdiLogin;
  mdiLogout: string = mdiLogout;
  mdiAccount: string = mdiAccount;
  rep:any;

  constructor(private router:Router,
              private afAuth: AngularFireAuth,
              private ngZone: NgZone,
              private store: AngularFirestore, 
              private fns: AngularFireFunctions,
              private prefService: PreferenceService,
              private csv: CustomerService,
              private pjService: ProjectService,
              public repService: RepService,
  ){}

  ngOnInit(){
    this.afAuth.auth.onAuthStateChanged((usr)=>{
      if(this.currentUser != '(not logined.)'){
        this.repService.getRep(); 
        this.repService.getDefaultRep();
        this.prefService.getPreferencies();
        this.csv.getCustomers();
        this.pjService.getProjects();
        this.ngZone.run(()=>{
          this.router.navigate(['']);
        });
      }      
    });

    this.router.events.subscribe((event) =>{
      if(event instanceof NavigationEnd){
        window.scrollTo(0, 0);
      }
    });
  }
  
  loginCheck(){
    if(this.currentUser == '(not logined.)'){
      this.login();
    }else{
      if(confirm('Are you logout now?')){
        this.logout();
      }
    }
  }
  login(){
    let provider = new firebase.auth.GoogleAuthProvider();
    this.afAuth.auth.signInWithRedirect(provider);
  }
  logout(){
    this.afAuth.auth.signOut();
    this.router.navigate(['']);
  }
  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
        this.repService.employee_name != null ?
          this.repService.employee_name:
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  get company(){
    return this.repService.company;
  }

  //レポート作成メニュー
  home() {
    this.router.navigate(['/home']);
  }

  //レポート作成メニュー
  report() {
    this.router.navigate(['/report']);
  }

  //レポート登録メニュー
  reportEdit() {
    this.router.navigate(['/report-edit']);
  }

  //レポート一覧メニュー
  reportList() {
      this.router.navigate(['/report-list']);
  }

  //案件一覧メニュー
  projectList() {
      this.router.navigate(['/project-list']);
  }

  //フォーキャストリストメニュー
  forecastList() {
    this.router.navigate(['/forecast-list']);
  }

  //顧客一覧メニュー
  customerList() {
      this.router.navigate(['/customer-list']);
  }

  //顧客詳細
  customerDetail() {
      this.router.navigate(['/customer-detail']);
  }

  //ユアページ
  yourPage() {
    this.router.navigate(['/your-page']);
  }

}
