import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-action-reload',
  templateUrl: './action-reload.component.html',
  styleUrls: ['./action-reload.component.css']
})
export class ActionReloadComponent implements OnInit {
  pj_id:string;

  constructor(
    private router:Router,
    private route: ActivatedRoute,
  ) { 
    this.pj_id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.router.navigate(['action-plan/'+this.pj_id]);
  }

}
