import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Report } from '../../class/report';
import { Customer } from '../../class/customer';
import { Project } from '../../class/project';
import { Status } from '../../class/status';
import { takeUntil, take } from 'rxjs/operators';
import { OrderMonthpickerComponent } from "../order-monthpicker/order-monthpicker.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PreferenceService } from '../../service/preference.service';
import { ProjectService } from '../../service/project.service';
import { CustomerService } from '../../service/customer.service';
import { RepService } from '../../service/rep.service';

export interface DialogData {
  new_project: string;
  select_cs: string;
}

import * as _moment from 'moment';
import {Moment} from 'moment';
import { strictEqual } from 'assert';
import { EventEmitter } from 'events';
import { forEach } from '@angular/router/src/utils/collection';

const moment = _moment;

function checkOrderMonth(c: FormControl){
  return c.value != '' ? null : {checkOrderMonth:{valid:false}};
}

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.css']
})
export class ReportEditComponent implements OnInit {

  public childOderMonth: string;
  public childReleaseMonth: string;

  /** 訪問日の初期値として現在日付けを取得する */
  public now = new Date();
 
  /** list of customers */
  protected customers: Customer[];

  /** control for the selected customer */
  public customerCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public customerFilterCtrl: FormControl = new FormControl();

  /** list of customers filtered by search keyword */
  public filteredCustomers: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);

  /** list of projects */
  protected projects: Project[];

  /** control for the selected project */
  public projectCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public projectFilterCtrl: FormControl = new FormControl();

  /** list of projects filtered by search keyword */
  public filteredProjects: ReplaySubject<Project[]> = new ReplaySubject<Project[]>(1);


  @ViewChild('singleSelect') singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  reportForm:FormGroup; 
  visit_sec_categories=[];
  phases=[];
  ranks=[];
  actions=[];
  latest_rank:number;
  new_project: string = "";
  select_cs: string;
  backup_projects : Project[];
  docs = [];
  protected projectsByCs: Project[];
  project:any;
  data:any;
  key:string;
  rep_category:string;
  min_date: Date;
  max_date: Date;
  max_due_date: Date;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router: Router,
    public dialog: MatDialog,
    private prefService: PreferenceService,
    private pjService: ProjectService,
    private csv: CustomerService,
    private repService: RepService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.visit_sec_categories = this.prefService.visit_sec_categories;
    //console.log("visit_sec_categories: ",this.visit_sec_categories);
    this.phases = this.prefService.phases;
    //console.log("phases: ",this.phases);
    this.ranks = this.prefService.ranks;
    //console.log("ranks: ",this.ranks);
    this.actions = this.prefService.actions;
    //console.log("actions: ",this.actions); 
  }

  ngOnInit() {
    if(!this.prefService.visit_sec_categories){
      alert("基本設定が完了していません。レポート作成は基本設定を行った後可能にまります。");
      this.router.navigate(['your-page']);
    }else{
      //明日以降の日付では入力不可
      this.max_date = new Date();
      //最大30日前までの日付で入力可
      const date = new Date();
      date.setDate(this.max_date.getDate() - 30);
      this.min_date = date;
      //最大1年後までNextアクションのdue_dateを設定許可
      let maxDueDate = new Date();
      maxDueDate.setDate(maxDueDate.getDate() + 365);
      this.max_due_date = maxDueDate;

      this.reportForm = new FormGroup({
        "visit_date": new FormControl("", [Validators.required]),
        "rep_name": new FormControl(this.currentUser),
        "cs_name": new FormControl("", [Validators.required]),
        "pj_name": new FormControl("", [Validators.required]),
        "new_pj_name": new FormControl("", [Validators.required]),
        "visit_section": new FormControl("", [Validators.required, Validators.maxLength(40)]),
        "visit_sec_category": new FormControl("", [Validators.required]),
        "visit_person": new FormControl("", [Validators.required, Validators.maxLength(40)]),
        "phase": new FormControl("", [Validators.required]),
        "purpose": new FormControl("", [Validators.required, Validators.maxLength(100)]),
        "status": new FormControl("", [Validators.required, Validators.maxLength(200)]),
        "requirement": new FormControl("", [Validators.required, Validators.maxLength(200)]),
        "sales": new FormControl("", [Validators.required, Validators.min(0), Validators.max(9999999999)]),
        "rank": new FormControl("", [Validators.required]),
        "competitor": new FormControl("", [Validators.required, Validators.maxLength(200)]),
        "next_action": new FormControl("", [Validators.required]),
        "due_date": new FormControl("", [Validators.required]),
      });

      ///////////////////////////////////////////////////
      //  顧客名セレクトボックスの設定
      ///////////////////////////////////////////////////

      //初期表示データ取得
      this.customers = this.csv.customers;

      // set initial selection
      this.customerCtrl.setValue(this.customers[10]);

      // load the initial customer list
      this.filteredCustomers.next(this.customers.slice());

      // listen for search field value changes
      this.customerFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCustomers();
      });

      ///////////////////////////////////////////////////
      //  案件名セレクトボックスの設定
      ///////////////////////////////////////////////////

      //初期表示データ取得
      this.projects = this.pjService.latest_pjs;

      this.backup_projects = this.projects; 

      // set initial selection
      this.projectCtrl.setValue(this.projects[10]);
      
      // load the initial project list
      this.filteredProjects.next(this.projects.slice());
      
      // listen for search field value changes
      this.projectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProjects();
      });

      this.new_pj_name.reset({ value: '', disabled: true });
    }
  }
  get visit_date(){return this.reportForm.get("visit_date");}
  get rep_name(){return this.reportForm.get("rep_name");}
  get cs_name(){return this.reportForm.get("cs_name");}
  get pj_name(){return this.reportForm.get("pj_name");}
  get new_pj_name(){return this.reportForm.get("new_pj_name");}
  get visit_section(){return this.reportForm.get("visit_section");}
  get visit_sec_category(){return this.reportForm.get("visit_sec_category");}
  get visit_person(){return this.reportForm.get("visit_person");}
  get phase(){return this.reportForm.get("phase");}
  get purpose(){return this.reportForm.get("purpose");}
  get status(){return this.reportForm.get("status");}
  get requirement(){return this.reportForm.get("requirement");}
  get sales(){return this.reportForm.get("sales");}
  get rank(){return this.reportForm.get("rank");}
  get competitor(){return this.reportForm.get("competitor");}
  get next_action(){return this.reportForm.get("next_action");}
  get due_date(){return this.reportForm.get("due_date");}
  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
        this.repService.employee_name != null ?
          this.repService.employee_name:
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredCustomers are loaded initially
   */
  protected setInitialValue() {
    this.filteredCustomers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredCustomers are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Customer, b: Customer) => a && b && a.id === b.id;
      });

    this.filteredProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredCustomers are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Project, b: Project) => a && b && a.id === b.id;
      });  
  }

  protected filterCustomers() {
    if (!this.customers) {
      return;
    }
    // get the search keyword
    let search = this.customerFilterCtrl.value;
    if (!search) {
      this.filteredCustomers.next(this.customers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the customers
    this.filteredCustomers.next(
      this.customers.filter(customer => customer.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProjects() {
    if (!this.projects) {
      return;
    }
    // get the search keyword
    let search = this.projectFilterCtrl.value;
    if (!search) {
      this.filteredProjects.next(this.projects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the projects
    this.filteredProjects.next(
      this.projects.filter(project => project.name.toLowerCase().indexOf(search) > -1)
    );
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(ProjectRegisterDialog, {
      width: '250px',
      data: {select_cs: this.cs_name.value.name, new_project: this.new_project}
    });

    dialogRef.afterClosed().subscribe(async result => {
      //console.log('The dialog was closed');

      if(result){
        this.pj_name.reset({ value: '', disabled: true });
        this.new_pj_name.reset({ value: result, disabled: false });

        //同一顧客へ同一案件名を登録させない
        let exist:boolean = false;
        for(var project of this.projects){
          if((project.cs_name == this.cs_name.value.name) && (project.name == result)){
            exist = true;
            alert("その案件は存在します。");
            this.new_pj_name.reset({ value: '', disabled: true });
            this.pj_name.reset({ value: result, disabled: false });
            return;
          }
        }
        if(!exist){
          // Add a new document with a generated id.
          this.store.collection("projects").add({
            rep_id: this.afAuth.auth.currentUser.uid,
            name: result,
            cs_name: this.cs_name.value.name,
            rep_name: this.rep_name.value,
          })
          .then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          }); 
        }
      }else{
        this.pj_name.reset({ value: '', disabled: false });
        this.new_pj_name.reset({ value: result, disabled: true });        
      }
   
    });
  }

  cancelProject():void {
    this.deleteProject();
    this.pj_name.reset({ value: '', disabled: false });
    this.new_pj_name.reset({ value: '', disabled: true });
    this.onChangeObj(this.cs_name.value);
  }

  //受注月を子コンポーネント（order-monthpicker.component)から取得
  onReceiveEventFromChild_Order(eventData: string) {
    this.childOderMonth = eventData;
    //console.log("eventData："+eventData);
    let timestampStr = JSON.stringify(eventData);
    //console.log("order_month_timestamp：",timestampStr);
    this.reportForm.addControl( 'order_month', new FormControl(moment(eventData).format()));
  }

  //リリース月を子コンポーネント（release-monthpicker.component)から取得
  onReceiveEventFromChild_Release(eventData: string) {
    this.childReleaseMonth = eventData;
    //console.log("eventData："+eventData);
    let timestampStr = JSON.stringify(eventData);
    //console.log("release_month_timestamp：",timestampStr);
    this.reportForm.addControl( 'release_month', new FormControl(moment(eventData).format()));
  }

  //セレクトボックス（顧客）の選択が変更された場合にその顧客に関する案件を取得
  onChangeObj(deviceValue) {
    this.projects = this.backup_projects;
    if(deviceValue){
      this.projects = this.getProjectsByCsName(deviceValue['name']);
      this.filteredProjects.next(this.projects.slice());
      this.new_pj_name.reset({ value: '', disabled: true });
    }
  }

  public alertPjDuplicate() {
    alert("その案件名はすでに登録されています。");
  }

  public save(){
    let now = new Date();
    this.reportForm.addControl( 'repo_date', new FormControl(now));
    this.reportForm.addControl( 'rep_id', new FormControl(this.afAuth.auth.currentUser.uid));
    this.reportForm.addControl( 'reported_flg', new FormControl(false));
    this.addReport(this.reportForm.value);
    this.router.navigate(['report']);
  }

  public submitReport(){
    let now = new Date();
    this.reportForm.addControl( 'repo_date', new FormControl(now));
    this.reportForm.addControl( 'rep_id', new FormControl(this.afAuth.auth.currentUser.uid));
    this.reportForm.addControl( 'reported_flg', new FormControl(true));
    this.addReport(this.reportForm.value);
    this.updateProject(this.reportForm.value); 
    this.router.navigate(['report-list']);   
  }

  updateProject(report:FormGroup){
    //console.log('report[pj_name]: ' + report['pj_name']);
    this.store.collection('projects',
      ref=>ref.where('name', '==', report['pj_name'])
              .where('cs_name', '==', this.cs_name.value.name)
              .where('rep_id', '==', this.afAuth.auth.currentUser.uid))
      .snapshotChanges()
      .subscribe(value=>{
        if(value[0] == undefined){
          console.log("(can't get data...1)");
          this.data = null;
          return;
        }
        this.key = value[0].payload.doc.id;
        this.data = value[0].payload.doc.data();
        this.project = this.data;

        this.addStatus();
        this.addActionPlan();

        return;
      },
      error=>{
        console.log("(can't update data...2)");
        this.data = null;
      });    
  }

  getProjectsByCsName(cs_name:string):Project[]{
    let resultArr: Project[] = [];
    for (let project of this.projects){  
      //console.log("pjCsName is :"+project.cs_name);
      if(project.cs_name == cs_name){
        resultArr.push(project);
        //console.log("project is :"+project.name);
      }
    }
    //console.log("resultArr is :"+resultArr);
    return resultArr;
  }

  deleteProject(){
    //console.log("new_pj_name is :"+this.new_pj_name.value);
    //console.log("cs_name is :"+this.cs_name.value.name);
    //console.log("afAuth.auth.currentUser.uid is :"+this.afAuth.auth.currentUser.uid);
    this.store.collection('projects',
      ref=>ref.where('name', '==', this.new_pj_name.value)
              .where('cs_name', '==', this.cs_name.value.name)
              .where('rep_id', '==', this.afAuth.auth.currentUser.uid))
      .snapshotChanges()
      .subscribe(value=>{
        if(value[0] == undefined){
          console.log("(can't get data...1)");
          this.data = null;
          return;
        }
        this.key = value[0].payload.doc.id;
        this.store.collection('projects')
          .doc(this.key)
          .delete()
          .then(() =>{
            alert('新規案件を削除しました。')
          });
      },
      error=>{
        console.log("(can't update data...2)");
        this.data = null;
      });    
  }

  //statusサブコレクションに追加
  addStatus(){
    let now = new Date();
    const follow = {
      follow_date: now,
      phase: this.phase.value,
      rank: this.rank.value,
      sales: this.sales.value,
      order_month: moment(this.childOderMonth).format(),
      release_month: moment(this.childReleaseMonth).format(),
      competitor: this.competitor.value
    };
    //console.log("follow：" +follow);
    this.store.collection('projects').doc(this.key)
    .collection('status').add(follow);
    return; 
  }

  //action_planサブコレクションに追加 
  addActionPlan(){     
    //console.log('this.project.name: ' + this.project.name);            
    const action = {
      next_action:this.next_action.value,
      due_date:this.due_date.value,
      action_status: "未実施",
      rep_id:this.afAuth.auth.currentUser.uid,
      cs_name:this.cs_name.value.name,
      pj_name:this.project.name
    };
    //console.log("action：" +action);
    this.store.collection('projects').doc(this.key)
    .collection('action_plan').add(action);
    return;
  }

  addReport(report:FormGroup){   

    let temp_cs_name = report['cs_name']['name'];
    delete report['cs_name'];
    report['cs_name']=temp_cs_name; 

    if(report['new_pj_name']){
      report['pj_name'] = report['new_pj_name'];
      delete report['new_pj_name'];
    }else{
      delete report['new_pj_name']; 
      let temp_pj_name = report['pj_name']['name'];
      delete report['pj_name'];
      report['pj_name']=temp_pj_name;
    }
    this.store.collection('reports').add(report);
  }

}

@Component({
  selector: 'project-register-dialog',
  templateUrl: 'project-register-dialog.html'
})

export class ProjectRegisterDialog {
  public newProjectData: String;
  constructor(
    public dialogRef: MatDialogRef<ProjectRegisterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

