import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.css']
})
export class ReloadComponent implements OnInit {
  pj_id:string;
  
  constructor(
    private router:Router,
    private route: ActivatedRoute,
  ) { 
    this.pj_id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.router.navigate(['project-forecast/'+this.pj_id]); 
  }
  
}
