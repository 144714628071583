import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Report } from '../../class/report';
import { Customer } from '../../class/customer';
import { Project } from '../../class/project';
import { Status } from '../../class/status';
import { takeUntil, take } from 'rxjs/operators';
import { OrderMonthpickerComponent } from "../order-monthpicker/order-monthpicker.component";
import { Router } from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { PreferenceService } from '../../service/preference.service';
import { RepService } from '../../service/rep.service';
import * as _moment from 'moment';
import {Moment} from 'moment';
import { strictEqual } from 'assert';
import { EventEmitter } from 'events';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
const moment = _moment;

@Component({
  selector: 'app-report-working',
  templateUrl: './report-working.component.html',
  styleUrls: ['./report-working.component.css']
})
export class ReportWorkingComponent implements OnInit {
  public childOderMonth: string;
  public childReleaseMonth: string;

  repo_id:string;
  report: any;
  message:string = 'wait...';
  data:any;
  key:string;
  project: any;

  store_order_month:string;
  store_release_month:string;

  /** 訪問日の初期値として現在日付けを取得する */
  public now = new Date();
 
  @ViewChild('singleSelect') singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  reportForm:FormGroup;
  latest_rank:number;

  visit_sec_categories=[];
  phases=[];
  ranks=[];
  actions=[];
  new_project: string = "";
  select_cs: string;
  backup_projects : Project[];
  docs = [];
  protected projectsByCs: Project[];
  rep_category:string;
  min_date: Date;
  max_date: Date;
  max_due_date: Date;

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute, 
    private router: Router,
    private location: Location,
    private prefService: PreferenceService,
    private repService: RepService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.repo_id = this.route.snapshot.paramMap.get('id');
    //console.log("repo_id: ",this.repo_id);
    this.visit_sec_categories = this.prefService.visit_sec_categories;
    //console.log("visit_sec_categories: ",this.visit_sec_categories);
    this.phases = this.prefService.phases;
    //console.log("phases: ",this.phases);
    this.ranks = this.prefService.ranks;
    //console.log("ranks: ",this.ranks);
    this.actions = this.prefService.actions;
    //console.log("actions: ",this.actions);
   }

  ngOnInit() {
    //明日以降の日付では入力不可
    this.max_date = new Date();
    //最大30日前までの日付で入力可
    const date = new Date();
    date.setDate(this.max_date.getDate() - 30);
    this.min_date = date;
    //最大1年後までNextアクションのdue_dateを設定許可
    let maxDueDate = new Date();
    maxDueDate.setDate(maxDueDate.getDate() + 365);
    this.max_due_date = maxDueDate;    

    this.getReport();
  }

  getReport(){
    this.store.collection('reports').doc(this.repo_id)
    .valueChanges()
    .subscribe(value=>{
      this.report = value;

      this.store_order_month = this.report.order_month;
      //console.log("store_order_month: ",this.store_order_month);
      this.store_release_month = this.report.release_month;
      //console.log("store_release_month: ",this.store_release_month);

      this.reportForm = new FormGroup({
        "visit_date": new FormControl(new Date(this.report.visit_date['seconds']*1000), [Validators.required]),
        "rep_name": new FormControl(this.report.rep_name, [Validators.required]),
        "cs_name": new FormControl(this.report.cs_name, [Validators.required]),
        "pj_name": new FormControl(this.report.pj_name, [Validators.required]),
        "visit_section": new FormControl(this.report.visit_section, [Validators.required, Validators.maxLength(40)]),
        "visit_sec_category": new FormControl(this.report.visit_sec_category, [Validators.required]),
        "visit_person": new FormControl(this.report.visit_person, [Validators.required, Validators.maxLength(40)]),
        "phase": new FormControl(this.report.phase, [Validators.required]),
        "purpose": new FormControl(this.report.purpose, [Validators.required, Validators.maxLength(100)]),
        "status": new FormControl(this.report.status, [Validators.required, Validators.maxLength(200)]),
        "requirement": new FormControl(this.report.requirement, [Validators.required, Validators.maxLength(200)]),
        "sales": new FormControl(this.report.sales, [Validators.required, Validators.min(0), Validators.max(9999999999)]),
        "rank": new FormControl(this.report.rank, [Validators.required]),
        "competitor": new FormControl(this.report.competitor, [Validators.required, Validators.maxLength(200)]),
        "next_action": new FormControl(this.report.next_action, [Validators.required]),
        "due_date": new FormControl(new Date(this.report.due_date['seconds']*1000), [Validators.required]), // convert timestamp to date
        "order_month": new FormControl(this.report.order_month, [Validators.required]),
        "release_month": new FormControl(this.report.release_month, [Validators.required]),
      });
      this.childOderMonth = this.report.order_month;
      this.childReleaseMonth = this.report.release_month;
      return;
    },
    error=>{
      this.message = "(ERROR:can't get data...2)";
      this.report = null;
    });
  }

  get visit_date(){return this.reportForm.get("visit_date");}
  get rep_name(){return this.reportForm.get("rep_name");}
  get cs_name(){return this.reportForm.get("cs_name");}
  get pj_name(){return this.reportForm.get("pj_name");}
  get visit_section(){return this.reportForm.get("visit_section");}
  get visit_sec_category(){return this.reportForm.get("visit_sec_category");}
  get visit_person(){return this.reportForm.get("visit_person");}
  get phase(){return this.reportForm.get("phase");}
  get purpose(){return this.reportForm.get("purpose");}
  get status(){return this.reportForm.get("status");}
  get requirement(){return this.reportForm.get("requirement");}
  get sales(){return this.reportForm.get("sales");}
  get rank(){return this.reportForm.get("rank");}
  get competitor(){return this.reportForm.get("competitor");}
  get next_action(){return this.reportForm.get("next_action");}
  get due_date(){return this.reportForm.get("due_date");}
  get order_month(){return this.reportForm.get("order_month");}
  get release_month(){return this.reportForm.get("release_month");}

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  backToList(){
    this.location.back();
  }

  //受注月を子コンポーネント（order-monthpicker.component)から取得
  onReceiveEventFromChild_Order(eventData: string) {
    this.childOderMonth = eventData;
    //console.log("eventData："+eventData);
    //let timestampStr = JSON.stringify(eventData);
    //console.log("order_month_timestamp：",timestampStr);
    this.reportForm.removeControl('order_month');
    this.reportForm.addControl( 'order_month', new FormControl(moment(eventData).format()));
  }

  //リリース月を子コンポーネント（release-monthpicker.component)から取得
  onReceiveEventFromChild_Release(eventData: string) {
    this.childReleaseMonth = eventData;
    //console.log("eventData："+eventData);
    //let timestampStr = JSON.stringify(eventData);
    //console.log("release_month_timestamp：",timestampStr);
    this.reportForm.removeControl('release_month');
    this.reportForm.addControl( 'release_month', new FormControl(moment(eventData).format()));
  }


  public alertNow() {
    let now = new Date();
    alert(formatDate(now, "yy/MM/dd HH:mm", this.locale));
  }

  public alertPjDuplicate() {
    alert("その案件名はすでに登録されています。");
  }

  public save(){
    let now = new Date();
    this.reportForm.addControl( 'repo_date', new FormControl(now));
    this.reportForm.addControl( 'rep_id', new FormControl(this.afAuth.auth.currentUser.uid));
    this.reportForm.addControl( 'reported_flg', new FormControl(false));
    this.updateReport(this.reportForm.value);
    this.router.navigate(['report']);
  }

  public submitReport(){
    let now = new Date();
    this.reportForm.addControl( 'repo_date', new FormControl(now));
    this.reportForm.addControl( 'rep_id', new FormControl(this.afAuth.auth.currentUser.uid));
    this.reportForm.addControl( 'reported_flg', new FormControl(true));
    this.updateReport(this.reportForm.value);
    this.updateProject(this.reportForm.value); 
    this.router.navigate(['report-list']);   
  }

  updateProject(report:FormGroup){
    let now = new Date();
    let update_dataStr = JSON.stringify(report);
    //consoleconsole.log("update_project："+update_dataStr);
    //console.log("pj_name："+report['pj_name']);
    //console.log("cs_name："+this.cs_name.value.name);
    //console.log("rep_id："+this.afAuth.auth.currentUser.uid);

    this.store.collection('projects',
      ref=>ref.where('name', '==', report['pj_name'])
              .where('cs_name', '==', this.cs_name.value)
              .where('rep_id', '==', this.afAuth.auth.currentUser.uid))
      .snapshotChanges()
      .subscribe(value=>{
        if(value[0] == undefined){
          this.message = "(can't get data...3)";
          this.data = null;
          return;
        }
        this.key = value[0].payload.doc.id;
        this.data = value[0].payload.doc.data();
        this.project = this.data;
        //consoleconsole.log('update_key：' + this.key);

        //statusサブコレクションに追加
        const follow = {
          follow_date: now,
          phase: this.phase.value,
          rank: this.rank.value,
          sales: this.sales.value,
          order_month: moment(this.childOderMonth).format(),
          release_month: moment(this.childReleaseMonth).format(),
          competitor: this.competitor.value
        };
        this.store.collection('projects').doc(this.key)
                  .collection('status').add(follow);

        //action_planサブコレクションに追加          
        const action = {
          next_action:this.next_action.value,
          due_date:this.due_date.value,
          action_status: "未実施",
          rep_id:this.afAuth.auth.currentUser.uid,
          cs_name:this.cs_name.value,
          pj_name:this.pj_name.value
        };
        this.store.collection('projects').doc(this.key)
                  .collection('action_plan').add(action);
      },
      error=>{
        this.message = "(can't update data...4)";
        this.data = null;
      });    
  }

  updateReport(report:FormGroup){    
    this.store.collection('reports').doc(this.repo_id).set(report);    
  }
}

