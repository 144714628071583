import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Project } from '../../class/project';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MatSelect } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Action } from '../../class/action';
import { PreferenceService } from '../../service/preference.service';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.css']
})
export class ActionPlanComponent implements OnInit {
  displayedColumns: string[] = ['next_action', 'due_date', 'action_status','action_date'];
  dataSource:[];
  data:any;
  key:string;
  actions = [];
  currentValue:string = '';
  values = '';
  backup_actions = [];
  pj_id:string;
  nextActionForm:FormGroup;
  next_actions=[];
  rep_category: string;
  min_date: Date;
  max_due_date: Date;

  constructor(
    private route: ActivatedRoute,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private prefService: PreferenceService,
    private repService: RepService,
    public pjService: ProjectService,
  ) {
    this.rep_category = this.repService.rep_category;   
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.pjService.getProjectById(this.pj_id);
    this.next_actions = this.prefService.actions;
    this.getActionPlan();
  }

  ngOnInit() {
    //最大30日前までの日付で入力可
    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 30);
    this.min_date = minDate;
    //最大1年後までNextアクションのdue_dateを設定許可
    let maxDueDate = new Date();
    maxDueDate.setDate(maxDueDate.getDate() + 365);
    this.max_due_date = maxDueDate;

    this.nextActionForm = new FormGroup({
      "next_action": new FormControl("", [Validators.required]),
      "due_date": new FormControl("", [Validators.required]),
    });
  }

  get next_action(){return this.nextActionForm.get("next_action");}
  get due_date(){return this.nextActionForm.get("due_date");}
  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  getActionPlan(){
    let i = 0;
    this.store.collection('projects').doc(this.pj_id)
              .collection('action_plan',
              ref => ref.orderBy('due_date','desc'))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            console.log("(can't get data...)");
            this.data = null;
            return;
          }
          this.key = value[i].payload.doc.id;
          this.data = value[i].payload.doc.data();
          this.data["id"] = this.key;
          this.data["pj_id"] = this.pj_id;        
          this.actions = this.actions.concat(this.data).slice() || [];
          //console.log("Action Plan !");
          i=i+1;
        })
        this.backup_actions = this.actions;
      },
      error=>{
        console.log("(can't get data...)");
        this.data = null;
      });
  }

  searchAction(keyword: string){
    let resultArr: Project[] = [];

    for (let action of this.actions){
      let dataStr = JSON.stringify(action);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(action);
      }
    }
    this.actions =  resultArr;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }

  public submitNextAction(){
    this.updateProject(this.nextActionForm.value);
    this.router.navigate(['action-reload/'+this.pj_id]);    
  }
  
  //action_planサブコレクションに追加  
  updateProject(nextActionForm:FormGroup){            
    const action = {
      next_action:this.next_action.value,
      due_date:this.due_date.value,
      action_status: "未実施",
      rep_id:this.afAuth.auth.currentUser.uid,
      cs_name:this.pjService.project.cs_name,
      pj_name:this.pjService.project.name
    };
    this.store.collection('projects').doc(this.pj_id)
              .collection('action_plan').add(action);                
  }

  onKey(event: any) { // without type info
    this.actions = this.backup_actions;
    this.values = event.target.value;
    this.searchAction(this.values);
  }

  onClickAction(select_act:Action){
    if(select_act.action_status == '未実施'){
      this.router.navigate(['/action-update/' + select_act.pj_id+'/'+select_act.id]);
    }else{
      this.router.navigate(['/action-detail/' + select_act.pj_id+'/'+select_act.id]);
    }
  }

}
