import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Project } from '../../class/project';
import { Router } from '@angular/router';
import { async } from '@angular/core/testing';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

@Component({
  selector: 'app-action-update',
  templateUrl: './action-update.component.html',
  styleUrls: ['./action-update.component.css']
})
export class ActionUpdateComponent implements OnInit {

  pj_id:string;
  act_id:string;
  action: any;
  actionUpdateForm:FormGroup;
  action_statuses=["実施済","ペンディング"];
  rep_category:string;
 
  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute, 
    private router: Router,
    private location: Location,
    private repService: RepService,
    public pjService: ProjectService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.act_id = this.route.snapshot.paramMap.get('act_id');
    this.pjService.getProjectById(this.pj_id);
    this.getActionplan();
   }

  ngOnInit() {
    this.actionUpdateForm = new FormGroup({
      "next_action": new FormControl("", [Validators.required]),
      "due_date": new FormControl("", [Validators.required]),
      "action_status": new FormControl("実施済", [Validators.required]), // convert timestamp to date
      "comment": new FormControl("", [Validators.required]),
    });
  }

  get next_action(){return this.actionUpdateForm.get("next_action");}
  get due_date(){return this.actionUpdateForm.get("due_date");}
  get action_status(){return this.actionUpdateForm.get("action_status");}
  get comment(){return this.actionUpdateForm.get("comment");}

  getActionplan(){
    //アクションサブコレクションからドキュメントを取得
    this.store.collection('projects').doc(this.pj_id)
    .collection('action_plan').doc(this.act_id)
    .valueChanges()
    .subscribe(value=>{
      this.action = value;
      //console.log("action: ",this.action);

      //this.next_action.reset({value:this.action.next_action});
      //this.due_date.reset({value:formatDate(this.action.due_date.toDate(),"dd/MM/yyyy",this.locale)});  
      this.actionUpdateForm.removeControl('next_action');
      this.actionUpdateForm.removeControl('due_date');
      this.actionUpdateForm.addControl('next_action', new FormControl(this.action.next_action, [Validators.required]));
      this.actionUpdateForm.addControl('due_date', new FormControl(formatDate(this.action.due_date.toDate(),"MM/dd/yyyy",this.locale), [Validators.required]));
      /*
      this.actionUpdateForm = new FormGroup({
        "next_action": new FormControl(this.action.next_action, [Validators.required]),
        "due_date": new FormControl(formatDate(this.action.due_date.toDate(),"dd/MM/yyyy",this.locale), [Validators.required]),
        "action_status": new FormControl("実施済", [Validators.required]), // convert timestamp to date
        "comment": new FormControl("", [Validators.required]),
      });
*/
    },
    error=>{
      console.log("(can't get data...)");
      this.action = null;
    });
  }

  backToList(){
    this.location.back();
  }

  submitAction(){
    let now = new Date();
    this.actionUpdateForm.addControl( 'action_date', new FormControl(now));
    this.actionUpdateForm.removeControl( 'due_date');
    this.actionUpdateForm.addControl( 'due_date', new FormControl(this.action.due_date));
    this.updateAction(this.actionUpdateForm.value); 
    this.router.navigate(['action-plan/'+ this.pj_id]); 
  }

  updateAction(update_action:FormGroup){
    let update_dataStr = JSON.stringify(update_action);
    //console.log("update_action："+update_dataStr);

    //action_planサブコレクションに追加          
    this.store.collection('projects').doc(this.pj_id)
              .collection('action_plan').doc(this.act_id)
              .update(update_action);   
  }

}
