import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { Report } from '../../class/report';
import { ReportService } from 'src/app/service/report.service';
import { Project } from '../../class/project';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { Status } from 'src/app/class/status';
import { Location, DatePipe } from '@angular/common';
import { RepService } from '../../service/rep.service';
import * as FileSaver from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { MatSort }  from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css'],
  providers:[DatePipe],
})
export class ReportListComponent implements OnInit {
  message: string;
  data: any;
  displayedColumns: string[] = ['repo_date', 'cs_name', 'pj_name'];  
  dataSource: MatTableDataSource<any>;
  key:string;
  reports = [];
  currentValue:string = '';
  values = '';
  backup_reports = [];
  cs_name:string;
  pj_name:string;
  rep_category:string;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private location: Location,
    private repService: RepService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.cs_name = this.route.snapshot.paramMap.get('cs_name');
    //console.log("cs_name: ",this.cs_name);
    this.pj_name = this.route.snapshot.paramMap.get('name');
    //console.log("pj_name: ",this.pj_name);
    this.data = null;
  }

  ngOnInit() {
    this.getReports();
  }

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  getReports(){    
    let i = 0;
    //メインメニューから開く時
    if(!this.pj_name){
      this.store.collection('reports',
        ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid)
                .where('reported_flg', '==', true))
        .snapshotChanges()
        .subscribe(value=>{
          value.forEach((doc)=>{
            if(value[i] == undefined){
              this.message = "(can't get data...)";
              this.data = null;
              return;
            }
            this.key = value[i].payload.doc.id;
            this.data = value[i].payload.doc.data();
            this.data["id"] = this.key;
            this.reports = this.reports.concat(this.data).slice() || [];
            this.message = 'Report list1.'
            i=i+1;
          })
          this.dataSource = new MatTableDataSource(this.reports);
          this.dataSource.sort = this.sort;
          this.backup_reports = this.reports;
        },
        error=>{
          this.message = "(can't get data...)";
          this.data = null;
        });
    //案件ページから開く時  
    }else{
      this.store.collection('reports',
      ref=>ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid)
              .where('reported_flg', '==', true)
              .where('cs_name', '==', this.cs_name)
              .where('pj_name', '==', this.pj_name))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
        if(value[i] == undefined){
          this.message = "(can't get data...)";
          this.data = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        this.data = value[i].payload.doc.data();
        this.data["id"] = this.key;
        this.reports = this.reports.concat(this.data).slice() || [];
        this.message = 'Report list2.'
        i=i+1;
        })
        this.dataSource = new MatTableDataSource(this.reports);
        this.dataSource.sort = this.sort;
        this.backup_reports = this.reports;
      },
      error=>{
        this.message = "(can't get data...)";
        this.data = null;
      });
    }
  }

  searchReport(keyword: string){
    let resultArr: Report[] = [];

    for (let report of this.reports){
      let dataStr = JSON.stringify(report);
      if(dataStr.search(keyword) >= 0){
        resultArr.push(report);
      }
    }
    this.dataSource = new MatTableDataSource(resultArr);
    this.dataSource.sort = this.sort;
    this.currentValue = keyword;
  }

  getCurrentValue(){
    return this.currentValue;
  }
  
  onKey(event: any) { // without type info
    this.reports = this.backup_reports;
    this.values = event.target.value;
    this.searchReport(this.values);
  }

  download(data:any){
    if(!data){
      alert("出力するレポートがありません。");
      return;
    }else{
      const replacer = (key, value) => value === null ? '' : value;
      const header = Object.keys(data[0]);
      let i = 0;
      data.forEach((doc)=>{
        delete data[i].id;
        delete data[i].rep_id;
  
        data[i]['due_date'] = this.datePipe.transform(data[i]['due_date'].toDate(), "yyyy/MM/dd");
        data[i]['repo_date'] = this.datePipe.transform(data[i]['repo_date'].toDate(), "yyyy/MM/dd");
        data[i]['visit_date'] = this.datePipe.transform(data[i]['visit_date'].toDate(), "yyyy/MM/dd");
        data[i]['order_month'] = this.datePipe.transform(data[i]['order_month'], "yyyy/MM");
        data[i]['release_month'] = this.datePipe.transform(data[i]['release_month'], "yyyy/MM");
        i=i+1;
      })
  
      //Leave off ID col and format
      const actualheader = ["競合", "顧客名", "実施予定日", "次回アクション", 
      "受注月", "フェーズ", "案件名",  "訪問目的", "受注確度", "納入月", 
      "営業担当者", "報告日", "報告フラグ", "顧客要望", "商談金額", "顧客状況", 
      "訪問日", "訪問者", "訪問部門区分", "訪問部門" ];
      //console.log(header);
  
      //console.log(data);
      let csv = data.map(row => header.map(fieldName => JSON.stringify
        (row[fieldName], replacer)).join(';'));
               csv.unshift(actualheader.join(';'));
               let csvArray = csv.join('\r\n');
        
               var blob = new Blob([csvArray], { type: 'text/csv' });
               FileSaver.saveAs(blob, "reportFile.csv");
    }
  }

  backToList(){
    this.location.back();
  }

  onClickReport(select_repo:Report){
      this.router.navigate(['/report-detail/' + select_repo.id]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(RepoCsvDownloadDialog);

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      if(result){
        this.download(this.reports);
        this.router.navigate(['repo-reload/']);
      }
    });
  }  
}

@Component({
  selector: 'repo-csv-download-dialog',
  templateUrl: 'repo-csv-download-dialog.html'
})
export class RepoCsvDownloadDialog {

}
