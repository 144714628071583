import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-repo-reload',
  templateUrl: './repo-reload.component.html',
  styleUrls: ['./repo-reload.component.css']
})
export class RepoReloadComponent implements OnInit {

  constructor(
    private router:Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.router.navigate(['report-list/']); 
  }

}
