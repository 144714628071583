import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Customer } from '../../class/customer';
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';
import { CustomerService } from '../../service/customer.service';
import { PreferenceService } from '../../service/preference.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css']
})
export class CustomerEditComponent implements OnInit {
  cs_id:string;
  customer: any;
  data:any;
  key:string;
  rep_category:string;
  customerForm:FormGroup;
  customers: Customer[]; 

  constructor(
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute, 
    private router: Router,
    private location: Location,
    private repService: RepService,
    private csv: CustomerService,
    private prefService: PreferenceService,
  ) { 
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.customers = this.csv.customers;
  }

  ngOnInit() {
    if(!this.prefService.visit_sec_categories){
      alert("基本設定が完了していません。顧客登録は基本設定を行った後可能にまります。");
      this.router.navigate(['your-page']);
    }else{
      this.customerForm = new FormGroup({
        "rep_name": new FormControl(this.currentUser, [Validators.required]),
        "name": new FormControl("", [Validators.required, Validators.maxLength(40)]),
        "address": new FormControl("", Validators.maxLength(80)),
        "tel": new FormControl("", Validators.maxLength(20)),
        "url": new FormControl("", Validators.maxLength(40)),
        "capital": new FormControl("", [Validators.min(1), Validators.max(9999999999)]),
        "number_employees": new FormControl("", [Validators.min(1), Validators.max(999999)]),
      });
    }
  }
  get rep_name(){return this.customerForm.get("rep_name");}
  get name(){return this.customerForm.get("name");}
  get address(){return this.customerForm.get("address");}
  get tel(){return this.customerForm.get("tel");}
  get url(){return this.customerForm.get("url");}
  get capital(){return this.customerForm.get("capital");}
  get number_employees(){return this.customerForm.get("number_employees");}

  get currentUser(){
    return this.afAuth.auth != null ?
      this.afAuth.auth.currentUser != null ?
        this.repService.employee_name != null ?
          this.repService.employee_name:
       this.afAuth.auth.currentUser.displayName : 
       '(not logined.)' :
      '(not logined.)'; 
  }

  public submitCustomer(){
    //同一顧客名は登録させない
    let exist:boolean = false;
    for(var customer of this.customers){
      if(customer.name == this.name.value){
        exist = true;
        alert("その案件は存在します。");
        return;
      }
    }
    if(!exist){
      let now = new Date();
      this.customerForm.addControl( 'update_date', new FormControl(now));
      this.customerForm.addControl( 'rep_id', new FormControl(this.afAuth.auth.currentUser.uid));
      this.addCustomer(this.customerForm.value);
      this.router.navigate(['customer-list']);
    }   
  }

  addCustomer(customer:FormGroup){
    this.store.collection('customers').add(customer);
  }

  backToList(){
    this.location.back();
  }

  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'textarea') {
      return false;
    }
  }
}
