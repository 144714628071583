import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { RepService } from '../../service/rep.service';
import { ProjectService } from '../../service/project.service';

@Component({
  selector: 'app-action-detail',
  templateUrl: './action-detail.component.html',
  styleUrls: ['./action-detail.component.css']
})
export class ActionDetailComponent implements OnInit {

  pj_id:string;
  act_id:string;
  status:any;
  action:any;

  rep_category:string;

  constructor(
    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private store: AngularFirestore,
    private location: Location, 
    private router:Router,
    private repService: RepService,
    public pjService: ProjectService,
    ) { }

  ngOnInit() {
    this.rep_category = this.repService.rep_category;
    if((!this.rep_category)||(this.rep_category == 'expire')||(this.rep_category == 'cancel')){
      this.router.navigate(['/your-page']);
    }
    this.pj_id = this.route.snapshot.paramMap.get('id');
    this.act_id = this.route.snapshot.paramMap.get('act_id');
    this.pjService.getProjectById(this.pj_id);
    this.getActionPlan();
  }
  backToList(){
    this.location.back();
  }

  getActionPlan(){
    this.store.collection('projects').doc(this.pj_id)
              .collection('action_plan').doc(this.act_id)
              .valueChanges()
              .subscribe(value=>{
                this.action = value;
              },
              error=>{
                this.action = null;
              });
  }
}
