import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, LOCALE_ID, Output} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Project } from '../../class/project';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MatSelect } from '@angular/material';;
import { Forecast } from '../../class/forecast';
import { RepService } from '../../service/rep.service';
import { PreferenceService } from '../../service/preference.service';
import * as FileSaver from 'file-saver';
import { Location, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSort }  from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

const SALES_CATEGORY_NUMBER = 3; 

export interface ForecastElement {
  id: number;
  rep_id:string;
  rep_name:string;
  name: string;
  cs_name: string;
  sales: number;
  sales_category_names: string[];
  phase:string;
  rank:string;
  order_month:string;
  first_month_sales: number[];
  first_month_total: number;
  second_month_sales: number[];
  second_month_total: number;
  third_month_sales: number[];
  third_month_total: number;
  fourth_month_sales: number[];
  fourth_month_total: number;
  fifth_month_sales: number[];
  fifth_month_total: number;
  sixth_month_sales: number[];
  sixth_month_total: number;
  seventh_month_sales: number[];
  seventh_month_total: number;
  eighth_month_sales: number[];
  eighth_month_total: number;
  nineth_month_sales: number[];
  nineth_month_total: number;
  tenth_month_sales: number[];
  tenth_month_total: number;
  eleventh_month_sales: number[];
  eleventh_month_total: number;
  fwelfth_month_sales: number[];
  fwelfth_month_total: number;
  sales_category_total: number[];
  sales_total: number;
}

@Component({
  selector: 'app-forecast-list',
  templateUrl: './forecast-list.component.html',
  styleUrls: ['./forecast-list.component.css'],
  providers:[DatePipe],
})
export class ForecastListComponent implements OnInit {
  message: string;
  displayedColumns: string[] = ['name', 'cs_name', 'phase', 'rank', 'order_month', 'sales_category_names', 'sales_category_total', 'sales_total', 
                                'first_month', 'first_month_total', 'second_month', 'second_month_total', 'third_month', 'third_month_total', 
                                'fourth_month', 'fourth_month_total', 'fifth_month', 'fifth_month_total', 'sixth_month', 'sixth_month_total',
                                'seventh_month', 'seventh_month_total', 'eighth_month', 'eighth_month_total', 'nineth_month', 'nineth_month_total', 
                                'tenth_month', 'tenth_month_total', 'eleventh_month', 'eleventh_month_total', 'twelfth_month', 'twelfth_month_total'];
  dataSource: MatTableDataSource<any>;
  data1:any;
  data2:any;
  data3:any;
  data4:any;
  key:string;
  forecast_list = [];
  forecasts = [];
  currentValue:string = '';
  values = '';
  backup_forecast_list = [];
  pj_id:string;
  project:any;

  forecastListForm:FormGroup;
  select_rank:number;
  ranks=[];
  rep:any;
  forecast_year:number; 
  forecast_section:string; 
  budgets=[];
  year_month=[];
  monthly_sales:number[][] = new Array();

  spans = [];
  tempRowId = null;
  tempRowCount = null;

  fc_list: ForecastElement[];
  sales_category_names = [];

  status:any;
  latest_rank:number;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private store: AngularFirestore, 
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router:Router,
    private repService: RepService,
    private prefService: PreferenceService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
    this.rep = this.repService.rep;
    if(this.rep.rep_category){
      if(this.rep.rep_category != 'forecast'){
        alert(this.afAuth.auth.currentUser.displayName + "さんはオプションサービスを利用していないためフォーキャスト機能を使うことはできません。")
        this.router.navigate(['/your-page']);
      }else{
        this.ranks=["全て",
        this.prefService.ranks[0] + "のみ",
        this.prefService.ranks[1] + "以上",
        this.prefService.ranks[2] + "以上",
        this.prefService.ranks[3] + "以上"]
  
        this.forecastListForm = new FormGroup({
          "rank": new FormControl("全て", [Validators.required]),
        });
  
        //console.log("rank: ", this.forecastListForm.value.rank);
        this.getRep(this.forecastListForm.value.rank); 
      }
    }
  }

  ngOnInit() {

  }

  get rank(){return this.forecastListForm.get("rank");}

  getRep(rank:string){
    this.fc_list=[];
    //repsコレクションから該当するユーザーの基本情報（売上区分）を取得
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
    .valueChanges()
    .subscribe(value=>{
      this.rep = value;
      //let repStr = JSON.stringify(this.rep);
      //console.log("rep: ", repStr);
      //console.log("year: ", JSON.stringify(this.rep["forecast_year"].slice(0,4)));
      //console.log("start_month: ", JSON.stringify(this.rep["start_month"]));

      this.sales_category_names=[
        this.rep.sales_category_1,
        this.rep.sales_category_2,
        this.rep.sales_category_3
      ];

      this.year_month=[];
      this.forecast_year = Number(this.rep.forecast_year.slice(0,4));
      this.forecast_section = this.rep.forecast_year.slice(5);

      //フォーキャストリストのカレンダー作成
      let start_month = this.rep.start_month;
      for(let i=0; i<12; i++){
        let month = parseInt(start_month)+i;
        let year = this.forecast_year;
        //console.log("let year: ", year);
        //console.log("let month: ", month);

        if(month<10){
          this.year_month[i]=year +'-0' + month;
        }else if(month<13){
          this.year_month[i]=year + '-' + month;
        }else{
          this.year_month[i]=(year+1) + '-0' + (month-12); 
        }
      }

      this.rep["sales_category_names"] = this.sales_category_names;
      this.rep["select_rank"] = rank;
      //let dataStr = JSON.stringify(this.rep);
      //console.log("reps："+dataStr);
      this.getBudget();
      this.getProjects(this.rep);
    },
    error=>{
      this.message = "(can't get data...)";
      this.rep = null;
    });
  }

  getBudget(){
    this.store.collection('reps').doc(this.afAuth.auth.currentUser.uid)
              .collection('budget',
              ref => ref.where('year', '==', this.forecast_year).where('section', '==', this.forecast_section))
              .valueChanges()
              .subscribe(value=>{
                this.data4 = value;

                this.budgets = [
                  this.data4[0].budget_category_1,
                  this.data4[0].budget_category_2,
                  this.data4[0].budget_category_3,
                ];
              },
              error=>{
                this.data4 = null;
              });
  }

  //選択したランクに応じて表示するドキュメントを絞り込む
  getProjects(rep:any){
    //console.log("rep.select_rank：" +rep.select_rank);
    if(rep.select_rank == this.ranks[0]){
      this.select_rank = 5;
    }else if(rep.select_rank == this.ranks[1]){
      this.select_rank = 1;
    }else if(rep.select_rank == this.ranks[2]){
      this.select_rank = 2;
    }else if(rep.select_rank == this.ranks[3]){
      this.select_rank = 3;
    }else if(rep.select_rank == this.ranks[4]){
      this.select_rank = 4;
    }

    //console.log("select_rank：" +this.select_rank);

    //プロジェクトコレクションのドキュメントを取得し
    //repsコレクションから取得した売上区分名を追加する。
    let i = 0;
    this.store.collection('projects',
    ref => ref.where('rep_id', '==', this.afAuth.auth.currentUser.uid))
    //.where('latest_rank', '<', this.select_rank))
    .snapshotChanges()
    .subscribe(value=>{
      value.forEach((doc)=>{
        if(value[i] == undefined){
          this.message = "(can't get data...)";
          this.data1 = null;
          return;
        }
        this.key = value[i].payload.doc.id;
        //console.log("key："+this.key);
        this.data1 = value[i].payload.doc.data();
        let dataStr = JSON.stringify(this.data1);
        //console.log("data1："+dataStr);
        this.data1["id"]=this.key;
        this.data1["sales_category_names"] = rep.sales_category_names;
        this.project = this.data1
        let pjStr = JSON.stringify(this.project);
        //console.log("project："+pjStr);
        this.message = "Project Forecast !";
        this.getStatus(this.project, rep);
        i=i+1;
      })
    },
    error=>{
    this.message = "(can't get data...)";
    this.data1 = null;
    });
  }

  //案件のステータスを取得し、選択されたランクで絞り込む
  getStatus(project:any, rep:any){
    this.store.collection('projects').doc(this.key)
    .collection('status',
    ref => ref.orderBy('follow_date','desc'))
    .snapshotChanges()
    .subscribe(value=>{
      if(value[0] == undefined){
        this.message = "(can't get data...)";
        this.status = null;
        return;
      }
      this.status = value[0].payload.doc.data();
      //console.log("status："+this.status);

      //ランクを数値化
      if(this.status.rank == "S"){
        this.latest_rank = 0;
      }else if(this.status.rank == "A"){
        this.latest_rank = 1;
      }else if(this.status.rank == "B"){
        this.latest_rank = 2;
      }else if(this.status.rank == "C"){
        this.latest_rank = 3;
      }else if(this.status.rank == "D"){
        this.latest_rank = 4;
      }

      project["phase"] = this.status.phase;
      project["rank"] = this.status.rank;
      project["order_month"] = this.status.order_month;
      //console.log("project："+ JSON.stringify(project));
      //console.log("rep："+ JSON.stringify(rep));

      //console.log("latest_rank："+this.latest_rank);
      //console.log("select_rank："+this.select_rank);

      //選択されたランク以上のフォーキャストのみを取得する
      if(this.latest_rank < this.select_rank ){
        this.getForecast(project, rep);
      }else{
        return;
      }
      
    },
    error=>{
      this.message = "(can't get data...)";
      this.status = null;
    });

  }

  getForecast(project:any, rep:any){
    let i = 0;
    let sales1_1 = 0;
    let sales1_2 = 0;
    let sales1_3 = 0;
    let sales2_1 = 0;
    let sales2_2 = 0;
    let sales2_3 = 0;
    let sales3_1 = 0;
    let sales3_2 = 0;
    let sales3_3 = 0;
    let sales4_1 = 0;
    let sales4_2 = 0;
    let sales4_3 = 0;
    let sales5_1 = 0;
    let sales5_2 = 0;
    let sales5_3 = 0;
    let sales6_1 = 0;
    let sales6_2 = 0;
    let sales6_3 = 0;
    let sales7_1 = 0;
    let sales7_2 = 0;
    let sales7_3 = 0;
    let sales8_1 = 0;
    let sales8_2 = 0;
    let sales8_3 = 0;
    let sales9_1 = 0;
    let sales9_2 = 0;
    let sales9_3 = 0;
    let sales10_1 = 0;
    let sales10_2 = 0;
    let sales10_3 = 0;
    let sales11_1 = 0;
    let sales11_2 = 0;
    let sales11_3 = 0;
    let sales12_1 = 0;
    let sales12_2 = 0;
    let sales12_3 = 0;
    let sales_category_1_total = 0;
    let sales_category_2_total = 0;
    let sales_category_3_total = 0;
   
    //console.log("key2："+this.key);

    //設定年度で選択した年度・部署で登録したフォーキャストだけを抽出する
    this.store.collection('projects').doc(project.id)
      .collection('forecast',
        ref => ref.where('forecast_year', '==', rep.forecast_year))
      .snapshotChanges()
      .subscribe(value=>{
        value.forEach((doc)=>{
          if(value[i] == undefined){
            console.log("can't get data...(forecast)");
            this.data3 = null;
            return;
          }
          this.data3 =  value[i].payload.doc.data();
          this.forecasts=this.data3;
          
          let forecastStr2 = JSON.stringify(this.forecasts);
          //console.log("forecasts："+forecastStr2);
          let release_monthStr = JSON.stringify(this.forecasts["release_month"].slice(0,7));
          //console.log("forecasts[release_month]："+release_monthStr);
   
          if(this.forecasts["release_month"].slice(0,7) == this.year_month[0]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales1_1 = sales1_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales1_2 = sales1_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales1_3 = sales1_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[1]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales2_1 = sales2_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales2_2 = sales2_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales2_3 = sales2_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[2]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales3_1 = sales3_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales3_2 = sales3_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales3_3 = sales3_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[3]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales4_1 = sales4_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales4_2 = sales4_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales4_3 = sales4_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[4]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales5_1 = sales5_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales5_2 = sales5_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales5_3 = sales5_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[5]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales6_1 = sales6_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales6_2 = sales6_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales6_3 = sales6_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[6]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales7_1 = sales7_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales7_2 = sales7_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales7_3 = sales7_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[7]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales8_1 = sales8_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales8_2 = sales8_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales8_3 = sales8_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[8]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales9_1 = sales9_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales9_2 = sales9_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales9_3 = sales9_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[9]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales10_1 = sales10_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales10_2 = sales10_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales10_3 = sales10_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[10]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales11_1 = sales11_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales11_2 = sales11_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales11_3 = sales11_3 + Number(this.forecasts["sales"]);
            }
          }else if(this.forecasts["release_month"].slice(0,7) == this.year_month[11]){
            if(this.forecasts["sales_category"]==project.sales_category_names[0]){
              sales12_1 = sales12_1 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[1]){
              sales12_2 = sales12_2 + Number(this.forecasts["sales"]);
            }else if(this.forecasts["sales_category"]==project.sales_category_names[2]){
              sales12_3 = sales12_3 + Number(this.forecasts["sales"]);
            }
          }
          i=i+1;
        })
        let first_month_sales = new Array();
        first_month_sales["0"]=sales1_1;
        first_month_sales["1"]=sales1_2;
        first_month_sales["2"]=sales1_3;

        let first_month_total = first_month_sales.reduce((acc, value) => acc + value);
        project["first_month_sales"]=first_month_sales;
        project["first_month_total"]=first_month_total;

        let second_month_sales = new Array();
        second_month_sales["0"]=sales2_1;
        second_month_sales["1"]=sales2_2;
        second_month_sales["2"]=sales2_3;

        let second_month_total = second_month_sales.reduce((acc, value) => acc + value);
        project["second_month_sales"]=second_month_sales;
        project["second_month_total"]=second_month_total;

        let third_month_sales = new Array();
        third_month_sales["0"]=sales3_1;
        third_month_sales["1"]=sales3_2;
        third_month_sales["2"]=sales3_3;

        let third_month_total = third_month_sales.reduce((acc, value) => acc + value);
        project["third_month_sales"]=third_month_sales;
        project["third_month_total"]=third_month_total;

        let fourth_month_sales = new Array();
        fourth_month_sales["0"]=sales4_1;
        fourth_month_sales["1"]=sales4_2;
        fourth_month_sales["2"]=sales4_3;

        let fourth_month_total = fourth_month_sales.reduce((acc, value) => acc + value);
        project["fourth_month_sales"]=fourth_month_sales;
        project["fourth_month_total"]=fourth_month_total;

        let fifth_month_sales = new Array();
        fifth_month_sales["0"]=sales5_1;
        fifth_month_sales["1"]=sales5_2;
        fifth_month_sales["2"]=sales5_3;

        let fifth_month_total = fifth_month_sales.reduce((acc, value) => acc + value);
        project["fifth_month_sales"]=fifth_month_sales;
        project["fifth_month_total"]=fifth_month_total;

        let sixth_month_sales = new Array();
        sixth_month_sales["0"]=sales6_1;
        sixth_month_sales["1"]=sales6_2;
        sixth_month_sales["2"]=sales6_3;

        let sixth_month_total = sixth_month_sales.reduce((acc, value) => acc + value);
        project["sixth_month_sales"]=sixth_month_sales;
        project["sixth_month_total"]=sixth_month_total;

        let seventh_month_sales = new Array();
        seventh_month_sales["0"]=sales7_1;
        seventh_month_sales["1"]=sales7_2;
        seventh_month_sales["2"]=sales7_3;

        let seventh_month_total = seventh_month_sales.reduce((acc, value) => acc + value);
        project["seventh_month_sales"]=seventh_month_sales;
        project["seventh_month_total"]=seventh_month_total;

        let eighth_month_sales = new Array();
        eighth_month_sales["0"]=sales8_1;
        eighth_month_sales["1"]=sales8_2;
        eighth_month_sales["2"]=sales8_3;

        let eighth_month_total = eighth_month_sales.reduce((acc, value) => acc + value);
        project["eighth_month_sales"]=eighth_month_sales;
        project["eighth_month_total"]=eighth_month_total;

        let nineth_month_sales = new Array();
        nineth_month_sales["0"]=sales9_1;
        nineth_month_sales["1"]=sales9_2;
        nineth_month_sales["2"]=sales9_3;

        let nineth_month_total = nineth_month_sales.reduce((acc, value) => acc + value);
        project["nineth_month_sales"]=nineth_month_sales;
        project["nineth_month_total"]=nineth_month_total;

        let tenth_month_sales = new Array();
        tenth_month_sales["0"]=sales10_1;
        tenth_month_sales["1"]=sales10_2;
        tenth_month_sales["2"]=sales10_3;

        let tenth_month_total = tenth_month_sales.reduce((acc, value) => acc + value);
        project["tenth_month_sales"]=tenth_month_sales;
        project["tenth_month_total"]=tenth_month_total;

        let eleventh_month_sales = new Array();
        eleventh_month_sales["0"]=sales11_1;
        eleventh_month_sales["1"]=sales11_2;
        eleventh_month_sales["2"]=sales11_3;
    
        let eleventh_month_total = eleventh_month_sales.reduce((acc, value) => acc + value);
        project["eleventh_month_sales"]=eleventh_month_sales;
        project["eleventh_month_total"]=eleventh_month_total;

        let twelfth_month_sales = new Array();
        twelfth_month_sales["0"]=sales12_1;
        twelfth_month_sales["1"]=sales12_2;
        twelfth_month_sales["2"]=sales12_3;

        let twelfth_month_total = twelfth_month_sales.reduce((acc, value) => acc + value);
        project["twelfth_month_sales"]=twelfth_month_sales;
        project["twelfth_month_total"]=twelfth_month_total;

        sales_category_1_total = sales1_1+sales2_1+sales3_1+sales4_1+sales5_1+sales6_1+sales7_1+sales8_1+sales9_1+sales10_1+sales11_1+sales12_1;
        sales_category_2_total = sales1_2+sales2_2+sales3_2+sales4_2+sales5_2+sales6_2+sales7_2+sales8_2+sales9_2+sales10_2+sales11_2+sales12_2;
        sales_category_3_total = sales1_3+sales2_3+sales3_3+sales4_3+sales5_3+sales6_3+sales7_3+sales8_3+sales9_3+sales10_3+sales11_3+sales12_3;

        let sales_category_total= new Array();
        sales_category_total["0"]=sales_category_1_total;
        sales_category_total["1"]=sales_category_2_total;
        sales_category_total["2"]=sales_category_3_total;

        let sales_total = 0;
        sales_total = sales_category_total.reduce((acc, value) => acc + value);
        project["sales_category_total"]=sales_category_total;
        project["sales_total"]=sales_total;

        this.forecast_list = this.forecast_list.concat(project).slice() || [];
        let forecastStr3 = JSON.stringify(this.forecast_list);
        //console.log("forecast_list："+forecastStr3);
        this.backup_forecast_list = this.forecast_list;
        
        //売上区分を毎にrow-spanを設定するため処理
        //売上区分以外のフィールドは重複してレコードを作成
        const DATA = this.forecast_list.reduce((current, next) => {
          next.sales_category_names.forEach(b => {
            current.push({ name: next.name, cs_name: next.cs_name, phase: next.phase, rank: next.rank, order_month: next.order_month, sales_category_names: b,
                          first_month_sales: next.first_month_sales, second_month_sales: next.second_month_sales, third_month_sales: next.third_month_sales, 
                          fourth_month_sales: next.fourth_month_sales, fifth_month_sales: next.fifth_month_sales, sixth_month_sales: next.sixth_month_sales,
                          seventh_month_sales: next.seventh_month_sales, eighth_month_sales: next.eighth_month_sales, nineth_month_sales: next.nineth_month_sales, 
                          tenth_month_sales: next.tenth_month_sales, eleventh_month_sales: next.eleventh_month_sales, twelfth_month_sales: next.twelfth_month_sales,
                          first_month_total: next.first_month_total, second_month_total: next.second_month_total, third_month_total: next.third_month_total, 
                          fourth_month_total: next.fourth_month_total, fifth_month_total: next.fifth_month_total, sixth_month_total: next.sixth_month_total,
                          seventh_month_total: next.seventh_month_total, eighth_month_total: next.eighth_month_total, nineth_month_total: next.nineth_month_total, 
                          tenth_month_total: next.tenth_month_total, eleventh_month_total: next.eleventh_month_total, twelfth_month_total: next.twelfth_month_total,
                          sales_category_total: next.sales_category_total, sales_total: next.sales_total, id: next.id
                      })
          });
          
          return current;
        }, []);
        //console.log(DATA)

        //設定年度に売上が発生する案件のみをフォーキャストリストに表示させる
        //sales_totalが０でないものだけに絞り込む
        const FORECAST_YEAR_DATA = DATA.filter((v) => v.sales_total !== 0);
        //console.log(FORECAST_YEAR_DATA);
        this.fc_list = FORECAST_YEAR_DATA;

        this.dataSource = new MatTableDataSource(this.fc_list);
        this.dataSource.sort = this.sort;

        //row-spanの対象フィールドの設定
        this.cacheSpan('name', d => d.name, this.fc_list.length);
        this.cacheSpan('cs_name', d => d.cs_name, this.fc_list.length);
        this.cacheSpan('phase', d => d.phase, this.fc_list.length);
        this.cacheSpan('rank', d => d.rank, this.fc_list.length);
        this.cacheSpan('order_month', d => d.order_month, this.fc_list.length);
        this.cacheSpan('sales_total', d => d.sales_total, this.fc_list.length);
        this.cacheSpan('first_month_total', d => d.first_month_total, this.fc_list.length);
        this.cacheSpan('second_month_total', d => d.second_month_total, this.fc_list.length);
        this.cacheSpan('third_month_total', d => d.third_month_total, this.fc_list.length);
        this.cacheSpan('fourth_month_total', d => d.fourth_month_total, this.fc_list.length);
        this.cacheSpan('fifth_month_total', d => d.fifth_month_total, this.fc_list.length);
        this.cacheSpan('sixth_month_total', d => d.sixth_month_total, this.fc_list.length);
        this.cacheSpan('seventh_month_total', d => d.seventh_month_total, this.fc_list.length);
        this.cacheSpan('eighth_month_total', d => d.eighth_month_total, this.fc_list.length);
        this.cacheSpan('nineth_month_total', d => d.nineth_month_total, this.fc_list.length);
        this.cacheSpan('tenth_month_total', d => d.tenth_month_total, this.fc_list.length);
        this.cacheSpan('eleventh_month_total', d => d.eleventh_month_total, this.fc_list.length);
        this.cacheSpan('twelfth_month_total', d => d.twelfth_month_total, this.fc_list.length);       
      },
      error=>{
        this.message = "(can't get data...)";
        this.forecast_list = null;
      });
  }

  getCurrentValue(){
    return this.currentValue;
  }

  onClickAction(select_forecast:Forecast){
    this.router.navigate(['/forecast-update/' + select_forecast.pj_id+'/'+select_forecast.id]);
  }

  /** Gets the total sales of all pj_forecast. */
  getTotalSales() {
    return this.forecast_list.map(t => Number(t.sales_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotalSales_1() {
    return this.forecast_list.map(t => Number(t.sales_category_total["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotalSales_2() {
    return this.forecast_list.map(t => Number(t.sales_category_total["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotalSales_3() { 
    return this.forecast_list.map(t => Number(t.sales_category_total["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_1stMonthSales() {
    return this.forecast_list.map(t => Number(t.first_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_1stMonthSales_1() {
    return this.forecast_list.map(t => Number(t.first_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_1stMonthSales_2() {
    return this.forecast_list.map(t => Number(t.first_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_1stMonthSales_3() {
    return this.forecast_list.map(t => Number(t.first_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_2ndMonthSales() {
    return this.forecast_list.map(t => Number(t.second_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_2ndMonthSales_1() {
    return this.forecast_list.map(t => Number(t.second_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_2ndMonthSales_2() {
    return this.forecast_list.map(t => Number(t.second_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_2ndMonthSales_3() {
    return this.forecast_list.map(t => Number(t.second_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_3rdMonthSales() {
    return this.forecast_list.map(t => Number(t.third_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_3rdMonthSales_1() {
    return this.forecast_list.map(t => Number(t.third_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_3rdMonthSales_2() {
    return this.forecast_list.map(t => Number(t.third_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_3rdMonthSales_3() {
    return this.forecast_list.map(t => Number(t.third_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_4thMonthSales() {
    return this.forecast_list.map(t => Number(t.fourth_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_4thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.fourth_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_4thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.fourth_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_4thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.fourth_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_5thMonthSales() {
    return this.forecast_list.map(t => Number(t.fifth_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_5thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.fifth_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_5thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.fifth_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_5thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.fifth_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_6thMonthSales() {
    return this.forecast_list.map(t => Number(t.sixth_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_6thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.sixth_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_6thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.sixth_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_6thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.sixth_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_7thMonthSales() {
    return this.forecast_list.map(t => Number(t.seventh_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_7thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.seventh_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_7thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.seventh_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_7thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.seventh_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_8thMonthSales() {
    return this.forecast_list.map(t => Number(t.eighth_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_8thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.eighth_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_8thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.eighth_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_8thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.eighth_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_9thMonthSales() {
    return this.forecast_list.map(t => Number(t.nineth_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_9thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.nineth_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_9thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.nineth_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_9thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.nineth_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  } 
  getTotal_10thMonthSales() {
    return this.forecast_list.map(t => Number(t.tenth_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_10thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.tenth_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_10thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.tenth_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_10thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.tenth_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }  
  getTotal_11thMonthSales() {
    return this.forecast_list.map(t => Number(t.eleventh_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_11thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.eleventh_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_11thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.eleventh_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_11thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.eleventh_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_12thMonthSales() {
    return this.forecast_list.map(t => Number(t.twelfth_month_total)).reduce((acc, value) => acc + value, 0);
  }
  getTotal_12thMonthSales_1() {
    return this.forecast_list.map(t => Number(t.twelfth_month_sales["0"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_12thMonthSales_2() {
    return this.forecast_list.map(t => Number(t.twelfth_month_sales["1"])).reduce((acc, value) => acc + value, 0);
  }
  getTotal_12thMonthSales_3() {
    return this.forecast_list.map(t => Number(t.twelfth_month_sales["2"])).reduce((acc, value) => acc + value, 0);
  }

  cacheSpan(key, accessor, fc_list_length) {
    for (let i = 0; i < fc_list_length;) {
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      
      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = SALES_CATEGORY_NUMBER;
      i += SALES_CATEGORY_NUMBER;
    }
  }

  getRowSpan(col, index) {  
    return this.spans[index] && this.spans[index][col];
  }

  //セレクトボックス（ランク）の選択が変更された場合にランクに応じた案件を取得
  onChangeObj(deviceValue) {
    //console.log("deviceValue is :"+deviceValue);
    this.getRep(deviceValue);
    this.forecast_list=[];
    this.dataSource = new MatTableDataSource();
  }

  onClickProject(select_project:Project){
    this.router.navigate(['/project-detail/' + select_project.id]);
  }

  
  download(data:any){
    if(!data){
      alert("出力するレポートがありません。");
      return;
    }else{
      const replacer = (key, value) => value === null ? '' : value;
      const header = Object.keys(data[0]);
      let i = 0;
      data.forEach((doc)=>{
        delete data[i].id;
        delete data[i].rep_id;
  
        data[i]['order_month'] = this.datePipe.transform(data[i]['order_month'], "yyyy/MM");
        i=i+1;
      })
  
      //Leave off ID col and format
      const actualheader = ["顧客名", "案件名",, "営業担当者",,"売上区分", 
      "フェーズ", "受注確度", "受注月", "第1月売上区分別sales","第1月sales_total",
      "第2月売上区分別sales","第2月sales_total", "第3月売上区分別sales","第3月sales_total",
      "第4月売上区分別sales","第4月sales_total", "第5月売上区分別sales","第5月sales_total",
      "第6月売上区分別sales","第6月sales_total", "第7月売上区分別sales","第7月sales_total",
      "第8月売上区分別sales","第8月sales_total", "第9月売上区分別sales","第9月sales_total",
      "第10月売上区分別sales","第10月sales_total", "第11月売上区分別sales","第11月sales_total",
      "第12月売上区分別sales","第12月sales_total", "年間売上区分別sales","年間sales_total"];
      //console.log(header);
  
      //console.log(data);
      let csv = data.map(row => header.map(fieldName => JSON.stringify
        (row[fieldName], replacer)).join(';'));
               csv.unshift(actualheader.join(';'));
               let csvArray = csv.join('\r\n');
        
               var blob = new Blob([csvArray], { type: 'text/csv' });
               FileSaver.saveAs(blob, "forecastFile.csv");
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ForecastCsvDownloadDialog);

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      if(result){
        this.download(this.forecast_list);
      }
    });
  }   
}

@Component({
  selector: 'forecast-csv-download-dialog',
  templateUrl: 'forecast-csv-download-dialog.html'
})
export class ForecastCsvDownloadDialog {

}