import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {ReportEditComponent} from '../report-edit/report-edit.component';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {Moment} from 'moment';
//import { group } from '@angular/animations';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-release-monthpicker',
  templateUrl: './release-monthpicker.component.html',
  styleUrls: ['./release-monthpicker.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ReleaseMonthpickerComponent implements OnInit{
  @Input() reportForm:FormGroup;
  @Input() storeReleaseMonth:string;
  @Input() pjForecastForm:FormGroup;

  //release_month = new FormControl(moment());
  @Output() event = new EventEmitter<String>();
  release_month:FormControl;
  
  min_date: Date;
  max_date: Date;

  constructor(){}

  ngOnInit(){
    //console.log('storeReleaseMonth: ',this.storeReleaseMonth);
    this.release_month = new FormControl(moment(this.storeReleaseMonth),[Validators.required]);  

    //最大1年後までの月を選択許可
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 365);
    this.max_date = maxDate;
    //最大1年前までの月を選択許可
    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 365);
    this.min_date = minDate;
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.release_month.value;
    ctrlValue.year(normalizedYear.year());
    this.release_month.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.release_month.value;
    ctrlValue.month(normalizedMonth.month());
    this.release_month.setValue(ctrlValue);
    this.event.emit(this.release_month.value)
    datepicker.close();
  }

}
